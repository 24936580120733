import ComponentRenderer from '../../../../../../../utils/with-plugins/helpers/component-renderer';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import productStateMapper from './product-state.mapper';

export default {
  [COMPONENT_TYPES.PRODUCT_STATE]: {
    component: ComponentRenderer,
    mappers: [productStateMapper],
  },
};
