import getClipMethods from '../../../common/helpers/get-clip-methods.helper';

const clipsWorkerMapper = (props, statics, _, context) => {
  const { figures, clipsConfig, clipsRef, clipsInfo } = props;
  const { deviceType } = statics;
  const { isCurrentClips, isPreviousClips } = clipsInfo;
  const { isFullscreen } = context;

  const position = { ...context.position, ...clipsInfo.position };
  const snap = { ...context.snap, ...clipsInfo.snap };

  const getMethods = ({
    multimediaClipRef,
    playerRef,
    isFullscreenRef,
    mouseHoverConfigRef,
    spinnerConfig,
    showSnackbar,
    multimediaWatched,
    setMultimediaWatched,
  }) =>
    getClipMethods({
      deviceType,
      clipsConfig,
      isCurrentClips,
      position,
      snap,
      multimediaClipRef,
      playerRef,
      isFullscreenRef,
      mouseHoverConfigRef,
      spinnerConfig,
      showSnackbar,
      multimediaWatched,
      setMultimediaWatched,
    });

  const getHandler = ({ multimediaClipRef, onClickVideo }) => () => {
    if (!multimediaClipRef.current.loading) {
      onClickVideo();
    }
  };

  return {
    getMethods,
    getHandler,
    figures,
    clipsConfig,
    deviceType,
    ref: clipsRef,
    position,
    snap,
    isCurrentClips,
    isPreviousClips,
    isFullscreen,
  };
};

export default clipsWorkerMapper;
