import { MULTIMEDIA_CONFIG } from './constants';

const getFigures = ({ config, multimedia, pictures, clips, videos }) => {
  const { show_top_in_gallery = true } = config.multimedia || {};
  const figures = Array.from(pictures);

  // step1: inject clips figures
  if (clips?.length > 0) {
    clips.forEach(clip => {
      const { position } = clip;

      if (typeof clip.position === 'number') {
        figures.splice(position, 0, clip);
      } else {
        figures.push(clip);
      }
    });
  }

  // step2: inject videos figures
  if (videos?.length > 0) {
    figures.push(...videos);
  }

  // step3: inject multimedia figures
  if (multimedia?.length > 0) {
    if (show_top_in_gallery) {
      figures.unshift(...multimedia);
    } else {
      figures.push(...multimedia);
    }
  }

  return figures.map((figure, index) => ({ ...figure, positionIndex: index }));
};

export default getFigures;
export { MULTIMEDIA_CONFIG };
