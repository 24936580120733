import {
  Main,
  PluginCoreDesktop as core,
  PluginOficialStoreDesktop as officialStore,
} from '../../../../components/header-with-plugins';
import headerModule from './header';

// PLUGINS
import withPlugins from '../../../../utils/with-plugins';
import compats from '../../../../verticals/compats/plugins/HEADER/defs/desktop';
import reviews from '../../../../verticals/reviews/plugins/HEADER/defs/desktop';

export default headerModule((featureName, namespace) =>
  withPlugins(core, compats, officialStore, reviews)({ debugMode: false, featureName, namespace })(Main),
);
