import GalleryProvider from './gallery-provider';

const withGalleryProvider = WrapperComponent => {
  const hoc = props => {
    const { figures, pictures, clipsConfig } = props;

    return (
      <GalleryProvider figures={figures} pictures={pictures} clipsConfig={clipsConfig}>
        <WrapperComponent {...props} />
      </GalleryProvider>
    );
  };

  hoc.displayName = `withGalleryProvider(${WrapperComponent.displayName || WrapperComponent.name})`;

  return hoc;
};

export default withGalleryProvider;
