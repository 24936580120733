import virtualTourMapper from './virtual-tour.mapper';

import GalleryContext from '../../../../../context';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { loadable } from 'nordic/lazy';

const VirtualTour = loadable(() => import('./virtual-tour.ui'));

export default {
  [COMPONENT_TYPES.VIRTUAL_TOUR]: {
    context: GalleryContext,
    mappers: [virtualTourMapper],
    component: VirtualTour,
  },
};
