import CompatsTitle from '../../../../../../../components/compats-widget/title';
import { COMPATS_COMPONENT_TYPES } from '../../../../../constants';
import compatsTitleMapper from '../../../common/components/compats-title/compats-title-mapper';

export default {
  [COMPATS_COMPONENT_TYPES.COMPATS_TITLE]: {
    component: CompatsTitle,
    mappers: [compatsTitleMapper],
  },
};
