import zoomMapper from './zoom.mapper';
import Zoom from './zoom.ui';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

export default {
  [COMPONENT_TYPES.ZOOM]: {
    mappers: [zoomMapper],
    component: Zoom,
  },
};
