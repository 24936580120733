const thumbnailMapper = (props, statics) => {
  const { figure, alt, id, picture, label, seeMoreAmount, onKeyDown, onMouseOver, onClick } = props;
  const { namespace } = statics;

  return {
    key: `gallery-thumbnail-img-${id}`,
    className: `${namespace}__thumbnail`,
    picture,
    label,
    alt,
    dataTestId: `thumbnail-${id}`,
    width: 44,
    height: 44,
    onKeyDown,
    onClick,
    overlayConfig: {
      provider: figure.provider,
      icon: {
        className: `${namespace}__thumbnail-icon`,
        ...figure.icon,
      },
      seeMoreLabel: {
        show: !!seeMoreAmount,
        className: `${namespace}__see-more`,
        text: +seeMoreAmount,
      },
    },
    wrapperLabel: {
      htmlFor: `gallery-thumbnail-${id}`,
      className: `${namespace}__label`,
      handleMouseOver: onMouseOver,
    },
  };
};

export default thumbnailMapper;
