import { COMPONENT_TYPES, BLANK_IMAGE, DEFAULT_GALLERY_LIMIT } from '../../../../../helpers/constants';

const getGallerySlides = ({ figures, limit }) =>
  figures.map((figure, index) => {
    const { alt, id, thumbnail, url, a11yLabel } = figure;

    const isOutLimit = figures.length > limit && index + 1 === limit;
    const seeMoreAmount = isOutLimit ? figures.length - limit + 1 : 0;
    const showThumbnail = index < limit;
    const thumbnailPicture = thumbnail
      ? {
          alt,
          id,
          src: url ? thumbnail : thumbnail.src,
          src2x: url ? thumbnail : thumbnail.src2x,
          a11yLabel,
        }
      : null;

    return {
      figure,
      seeMoreAmount,
      showThumbnail,
      thumbnailPicture,
      slidePosition: index,
    };
  });

const galleryColumnMapper = (props, statics, calculatedProps) => {
  const { figures, track, limit = DEFAULT_GALLERY_LIMIT, title = null, overlayAlpha } = props;
  const { namespace } = statics;
  const { setModalVisibility, zoomRef, zoomOptions } = calculatedProps;

  const className = `${namespace}__column`;

  return {
    wrapperConfig: {
      tag: 'div',
      props: { className },
    },
    list: [
      {
        type: COMPONENT_TYPES.IMAGE,
        props: {
          id: 'variation-gallery',
          alt: 'variation-gallery',
          className: `${className}__variation-gallery`,
          wrapperClassName: `${className}__variation-picture`,
          src: BLANK_IMAGE,
          lazyload: 'off',
          decoding: 'async',
        },
      },
      ...getGallerySlides({ figures, limit }).map(slide => ({
        id: slide.figure.id,
        type: COMPONENT_TYPES.GALLERY_SLIDE,
        props: { ...slide, track, setModalVisibility, zoomRef, zoomOptions, title, overlayAlpha },
      })),
    ],
  };
};

export default galleryColumnMapper;
