import React, { useState, useEffect } from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import { Button } from '@andes/button';
import classnames from 'classnames';
import LikeIcon from '../../../svg-sprites/icons/questions-ai/like';
import DislikeIcon from '../../../svg-sprites/icons/questions-ai/dislike';
import LikeFullIcon from '../../../svg-sprites/icons/questions-ai/like-full';
import DislikeFullIcon from '../../../svg-sprites/icons/questions-ai/dislike-full';

const namespace = `ui-vpp-questions-ai-user-feedback`;

const QuestionsAiUserFeedback = ({ itemId, actions, suggestion, isUseful }) => {
  const [stateDisliked, setStateDisliked] = useState(false);
  const [stateLiked, setStateLiked] = useState(false);
  const [like, dislike] = actions;
  const { id, response } = suggestion;

  useEffect(() => {
    setStateDisliked(false);
    setStateLiked(false);
  }, [response]);

  const likeDislikeHandler = (e, useful) => {
    e.preventDefault();

    let track = {};

    if (useful) {
      setStateLiked(true);
      setStateDisliked(false);
      track = like.track;
    } else {
      setStateDisliked(true);
      setStateLiked(false);
      track = dislike.track;
    }

    isUseful({ itemId, useful, id, response, track, source: 'vpp' });
  };

  return (
    <div className={namespace}>
      <div className={`${namespace}__buttons`}>
        <Button
          data-testid="like-button"
          hierarchy="transparent"
          role="switch"
          aria-checked={stateLiked}
          aria-label={like.label?.text}
          className={classnames(`${namespace}__button`, `${namespace}__button-like`, { active: stateLiked })}
          onClick={e => likeDislikeHandler(e, true)}
          active="true"
        >
          {stateLiked && <LikeFullIcon className={`${namespace}__button-icon`} />}
          {!stateLiked && <LikeIcon />}
        </Button>
        <Button
          data-testid="dislike-button"
          hierarchy="transparent"
          role="switch"
          className={classnames(`${namespace}__button`, `${namespace}__button-dislike`, { active: stateDisliked })}
          aria-checked={stateDisliked}
          aria-label={dislike.label?.text}
          onClick={e => likeDislikeHandler(e, false)}
        >
          {stateDisliked && <DislikeFullIcon data-testid="dislike" className={`${namespace}__likes__dislike`} />}
          {!stateDisliked && <DislikeIcon className={`${namespace}__likes__dislike`} />}
        </Button>
      </div>
    </div>
  );
};

QuestionsAiUserFeedback.propTypes = {
  itemId: string.isRequired,
  isUseful: func.isRequired,
  actions: arrayOf(
    shape({
      like: shape({
        track: shape({}).isRequired,
        label: shape({
          text: string.isRequired,
        }),
      }),
      dislike: shape({
        track: shape({}).isRequired,
        label: shape({
          text: string.isRequired,
        }),
      }),
    }),
  ).isRequired,
  suggestion: shape({
    id: string.isRequired,
    response: string.isRequired,
  }).isRequired,
};

export default QuestionsAiUserFeedback;
