import classnames from 'classnames';
import { PROVIDER } from '../../../../../../figure/constants';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { getShapes } from '../../../../../../dots-navigation';

const mainMapper = (props, statics, _, context) => {
  const {
    figures: allFigures,
    logo,
    highlightedMultimedia,
    id,
    accessibility_text,
    overlayAlpha,
    galleryType,
    minHeight,
    maxHeight,
    track,
  } = props;
  const { namespace } = statics;
  const { setIsFullscreen } = context;

  const MOBILE_PROVIDERS_ALLOWED = Object.values(PROVIDER).filter(provider => provider !== PROVIDER.YOUTUBE);
  const mobileFigures = allFigures?.filter(item => MOBILE_PROVIDERS_ALLOWED.includes(item.provider));

  const setHeaderUnclickeable = unclickeable => {
    const header = document.querySelector('.nav-header');
    if (header) {
      if (unclickeable) {
        header.style.pointerEvents = 'none';
      } else {
        header.style.pointerEvents = 'auto';
      }
    }
  };

  const getHandlers = ({ setModalVisibility, startTransition }) => ({
    handleBeforeOpen: () => {
      setIsFullscreen(true);
    },
    handleLightboxOnClose: () => {
      setModalVisibility(false);

      startTransition(() => {
        setTimeout(() => setHeaderUnclickeable(false), 200);
        setIsFullscreen(false);
      });
    },
  });

  const shapes = getShapes(mobileFigures);

  return {
    wrapperConfig: {
      tag: 'div',
      props: { className: classnames(namespace, { [`${namespace}-with-logo`]: logo }) },
    },
    getHandlers,
    list: [
      {
        type: COMPONENT_TYPES.IMAGE_LOADER,
        show: allFigures?.length === 1,
      },
      {
        type: COMPONENT_TYPES.IMAGE,
        props: { logo },
        show: !!logo,
      },
      {
        type: COMPONENT_TYPES.GALLERY_CAROUSEL,
        props: {
          setHeaderUnclickeable,
          id,
          accessibility_text,
          overlayAlpha,
          galleryType,
          minHeight,
          maxHeight,
          track,
          figures: mobileFigures,
          shapes,
          allFigures,
        },
      },
      {
        type: COMPONENT_TYPES.LIGHTBOX,
        props: {
          setHeaderUnclickeable,
          setIsFullscreen,
          figures: mobileFigures,
          highlightedMultimedia,
          shapes,
        },
        getShow: ({ modalVisibility }) => !highlightedMultimedia && modalVisibility,
      },
    ],
  };
};

export default mainMapper;
