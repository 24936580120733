import { useState, useMemo } from 'react';
import usePreviousWithState from '../../../hooks/use-previous-with-state';

const useGallery = items => {
  const initPosition = 0;
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(initPosition);

  const prevIndex = usePreviousWithState(currentIndex);

  const currentItem = items[currentIndex];
  const previousItem = items[prevIndex];

  return useMemo(
    () => ({
      position: {
        current: currentIndex,
        previous: prevIndex,
      },
      snap: {
        current: currentItem,
        previous: previousItem || {},
      },
      isFullscreen,
      setCurrentIndex,
      setIsFullscreen,
    }),
    [currentIndex, prevIndex, currentItem, previousItem, isFullscreen],
  );
};

export default useGallery;
