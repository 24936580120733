import addElementToList from '../../../../../../../utils/with-plugins/helpers/add-element-to-list';
import { POSITION } from '../../../../../../../utils/with-plugins/helpers/constants';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { OFFICIAL_STORE_TYPES } from '../../../common/constants';

const mainMapper = (props, statics) => {
  const { list, official_store } = props;
  const { namespace } = statics;

  const elementToInsert = {
    type: OFFICIAL_STORE_TYPES.OFFICIAL_STORE_LABEL,
    props: {
      className: `${namespace}__store`,
      official_store,
    },
    show: !!official_store,
  };

  return {
    list: addElementToList(list, elementToInsert, POSITION.AFTER, COMPONENT_TYPES.BOTTOM_SUBTITLE),
  };
};

export default mainMapper;
