import pictureMapper from './picture.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { loadable } from 'nordic/lazy';

const Picture = loadable(() => import('./picture.ui'));

export default {
  [COMPONENT_TYPES.PICTURE]: {
    mappers: [pictureMapper],
    component: Picture,
  },
};
