import { PROVIDER } from '../../../../../../figure/constants';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const getModuleId = (provider, id, positionIndex) => String(id || `${provider}(${positionIndex})`);

const galleryCarouselMapper = (props, _, calculatedProps) => {
  const { figures, utils, setHeaderUnclickeable, track, clipsConfig, clipHandlerAsync } = props;
  const { mergeFigures, getOnTapFigure: getOnTapFigureCore } = utils;
  const { setModalVisibility, setActiveModalTab } = calculatedProps;

  const clipsFigures = figures
    .filter(figure => figure.provider === PROVIDER.CLIP)
    .map((item, index) => {
      const { id, provider, button, positionIndex } = item;

      return {
        type: COMPONENT_TYPES.CLIP_FIGURE,
        id: getModuleId(provider, id, positionIndex),
        props: {
          id,
          index,
          item,
          track,
          setModalVisibility,
          setHeaderUnclickeable,
          setActiveModalTab,
          clipsConfig,
          clipHandlerAsync,
        },
        show: provider === PROVIDER.CLIP,
        data: { provider, button },
      };
    });

  const getOnTapFigure = ({ provider, button }) => e => {
    getOnTapFigureCore({ provider, button })(e);

    // Ejecuta el handler asíncrono fuera del bloque crítico
    if (clipsConfig?.hasClips) {
      setTimeout(() => clipHandlerAsync(PROVIDER.CLIP), 0);
    }
  };

  return {
    list: mergeFigures(clipsFigures).map(e => ({
      ...e,
      props: { ...e.props, onTapFigure: getOnTapFigure(e.data) },
    })),
  };
};

export default galleryCarouselMapper;
