import { COMPONENT_TYPES } from '../../../../../../../components/header-with-plugins';
import replaceElementInList from '../../../../../../../utils/with-plugins/helpers/replace-element-in-list';
import { COMPATS_COMPONENT_TYPES } from '../../../../../constants';

const titleContainerMapper = props => {
  const { list, title, titleClassName, title_compats } = props;

  if (!title_compats) {
    return {};
  }

  const elementToInsert = {
    type: COMPATS_COMPONENT_TYPES.COMPATS_TITLE,
    props: {
      title,
      titleClassName,
      title_compats,
    },
    show: !!title_compats,
  };

  return {
    list: replaceElementInList(list, elementToInsert, COMPONENT_TYPES.TITLE),
  };
};

export default titleContainerMapper;
