import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { Title } from '@vpp-frontend-components/title';
import titleMapper from './title.mapper';

export default {
  [COMPONENT_TYPES.TITLE]: {
    component: Title,
    mappers: [titleMapper],
  },
};
