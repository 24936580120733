import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const mainMapper = props => {
  const { list, figures, highlightedMultimedia } = props;

  return {
    list: [
      ...list,
      {
        type: COMPONENT_TYPES.VIRTUAL_TOUR,
        props: {
          figures,
          highlightedMultimedia,
        },
        getShow: ({ activeModalTab }) => highlightedMultimedia && !!activeModalTab,
      },
    ],
  };
};

export default mainMapper;
