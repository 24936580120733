import React from 'react';
import renderListComponents from './render-list-components';
import propTypes from './proptypes';
import { shape, string } from 'prop-types';
import SimpleWrapper from './simple-wrapper';

/**
 * Component that renders a list of components.
 * @function ComponentRenderer
 * @param {Object[]} list - The list of components to render.
 * @param {Object} componentsToRender - The components to render.
 * @param {Function} handleError - The error handler function.
 * @param {Object} metrics - The metrics object.
 * @param {Object} wrapperConfig - The component wrapper type.
 * @param {Object} calculatedProps - The other wrapper props.
 * @returns {Object} - The rendered component.
 */
const ComponentRenderer = props => {
  const { list, componentsToRender, handleError, metrics, wrapperConfig = {}, calculatedProps = {} } = props;
  try {
    return (
      <SimpleWrapper componentsToRender={componentsToRender} wrapperConfig={wrapperConfig}>
        {renderListComponents(list, componentsToRender, calculatedProps, null, handleError, metrics)}
      </SimpleWrapper>
    );
  } catch (error) {
    handleError(error, metrics);
    return null;
  }
};

ComponentRenderer.propTypes = {
  ...propTypes,
  tag: string,
  otherWrapperProps: shape({}),
};

export default ComponentRenderer;
