import React from 'react';
import { shape, string } from 'prop-types';
import { componentEnhance } from '@vpp-frontend-components/component-enhance';

const OfficialStore = props => {
  const { className, official_store } = props;
  return <div className={className}>{componentEnhance.jsx(official_store.text, official_store.values)}</div>;
};

OfficialStore.propTypes = {
  className: string,
  official_store: shape({
    text: string,
    values: shape({
      action: shape({
        type: string,
        target: string,
        label: shape({
          text: string,
        }),
      }),
    }),
  }),
};

export default OfficialStore;
