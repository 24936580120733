const bottomSubtitleMapper = (props, statics) => {
  const { bottom_subtitle } = props;
  const { namespace } = statics;
  return {
    className: `${namespace}__bottom-subtitle`,
    ...bottom_subtitle,
  };
};

export default bottomSubtitleMapper;
