import mainMapper from './main.mapper';
import { MAIN_TYPE } from '../../../../../../../utils/with-plugins/helpers/constants';
import GalleryContext from '../../../../../context';

export default {
  [MAIN_TYPE]: {
    mappers: [mainMapper],
    context: GalleryContext,
  },
};
