import classNames from 'classnames';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const mainMapper = (props, statics) => {
  const {
    tag,
    link_label,
    subtitle,
    titleClassName,
    title,
    children,
    bottom_subtitle,
    onScrollComponent,
    className,
  } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: classNames(namespace, className),
      },
    },
    list: [
      {
        type: COMPONENT_TYPES.PILL,
        props: {
          tag,
        },
        show: !!tag,
      },
      {
        type: COMPONENT_TYPES.SUBTITLE,
        props: {
          subtitle,
          link_label,
        },
        show: !!subtitle,
      },
      {
        type: COMPONENT_TYPES.TITLE_CONTAINER,
        props: {
          title,
          titleClassName,
          children,
        },
        show: true,
      },
      {
        type: COMPONENT_TYPES.BOTTOM_SUBTITLE,
        props: {
          bottom_subtitle,
        },
        show: !!bottom_subtitle,
      },
      {
        type: COMPONENT_TYPES.LINK_LABEL,
        props: {
          link_label,
          onScrollComponent,
        },
        show: !!link_label,
      },
    ],
  };
};

export default mainMapper;
