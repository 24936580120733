const zoomMapper = (_, statics, calculatedProps) => {
  const { namespace } = statics;
  const { zoomRef, galleryOptions } = calculatedProps;

  return {
    className: `${namespace}__zoom-container`,
    zoomRef,
    style: { top: galleryOptions?.top, left: galleryOptions?.left },
  };
};

export default zoomMapper;
