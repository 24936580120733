import { POSITION } from './constants';

/**
 * Add an element to a list at a specific position.
 * @function addElementToList
 * @param {Array} list - The list to add the element to.
 * @param {Object} element - The element to add to the list.
 * @param {string} position - The position to add the element in the list.
 * @param {string} referenceType - The type of the element to use as a reference for the position.
 * @returns {Array} - The updated list with the element added.
 */
const addElementToList = (list, element, position, referenceType) => {
  if (!Array.isArray(list)) {
    throw new Error('List must be an array');
  }

  if (!Object.values(POSITION).includes(position)) {
    throw new Error('Invalid position. Position must be one of BEFORE or AFTER');
  }

  if (typeof referenceType !== 'string') {
    throw new Error('Reference type must be a string');
  }

  const updatedList = Array.from(list);

  const insertAtIndex = (index, offset = 0) => {
    if (index !== -1) {
      updatedList.splice(index + offset, 0, element);
    }
  };

  const positionHandlers = {
    [POSITION.BEFORE]: () => insertAtIndex(updatedList.findIndex(item => item.type === referenceType)),
    [POSITION.AFTER]: () => insertAtIndex(updatedList.findIndex(item => item.type === referenceType), 1),
  };

  positionHandlers[position]?.();

  return updatedList;
};

export default addElementToList;
