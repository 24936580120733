import { PROVIDER } from '../../../../../../figure/constants';

const virtualTourMapper = props => {
  const { figures, clipsConfig, clipsRef, clipsInfo, position, utils } = props;
  const { mergeFigures } = utils;

  return {
    figures: mergeFigures(figures?.filter(({ provider }) => provider === PROVIDER.CLIP)),
    onTouch: (...params) => clipsRef?.current && clipsRef?.current?.handleTouch(...params),
    position: { ...position, ...clipsInfo.position },
    otherProps: {
      hasClips: clipsConfig?.hasClips,
    },
  };
};

export default virtualTourMapper;
