import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import {
  MainDesktop as Gallery,
  PluginCoreDesktop as corePlugin,
  PluginClipsDesktop as clipsPlugin,
  withGalleryProvider,
} from '../../../../components/gallery-with-plugins';
import jsonToProps from '../../../../components/gallery-with-plugins/helpers/json-to-props';
import withPlugins from '../../../../utils/with-plugins';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'gallery_octopus';
const GALLERY = 'gallery';

const mapStateToProps = ({
  components: {
    fixed: { [GALLERY]: gallery },
    header: { title },
  },
}) => jsonToProps({ ...gallery, titleForAlt: title });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  showSnackbar: props => dispatch(actions.showSnackbar({ ...props })),
});

const enhance = compose(
  withFeatureFlag(`fixed.${FEATURE_NAME}`, { pathToComponent: 'components.fixed.gallery' }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withGalleryProvider,
  withPlugins(corePlugin, clipsPlugin)({
    debugMode: false,
    namespace: 'ui-pdp-gallery',
    featureName: FEATURE_NAME,
  }),
);

export default enhance(Gallery);
