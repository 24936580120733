import MAIN from './main';
import BOTTOM_SUBTITLE from './bottom-subtitle';
import LINK_LABEL from './link-label';
import PILL from './pill';
import SUBTITLE from './subtitle';
import TITLE_CONTAINER from './title-container';
import TITLE from './title';
import CHILDREN from './children';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const core = () => ({
  ...MAIN,
  ...BOTTOM_SUBTITLE,
  ...LINK_LABEL,
  ...PILL,
  ...SUBTITLE,
  ...TITLE_CONTAINER,
  ...TITLE,
  ...CHILDREN,
});

core.ownership = OWNERSHIPS.VPP;
core.isCore = true;

export default core;
