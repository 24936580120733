const getMultimedia = highlighted_multimedia =>
  highlighted_multimedia
    ? [
        {
          ...highlighted_multimedia,
          thumbnail: highlighted_multimedia.thumbnail?.url?.src,
          src: highlighted_multimedia.thumbnail?.url?.src,
          alt: 'Tour Virtual',
          icon: highlighted_multimedia.button?.content?.icon,
          showTopInGallery: highlighted_multimedia.show_top_in_gallery ?? true,
          provider: highlighted_multimedia.provider?.toLowerCase?.(),
        },
      ]
    : [];

export default getMultimedia;
