import React from 'react';
import { node, shape, arrayOf } from 'prop-types';

import useGallery from '../hooks/use-gallery.hook';
import GalleryContext from '.';

const GalleryProvider = ({ children, figures, pictures }) => {
  const items = figures || pictures || {};
  const galleryContextData = useGallery(items);

  return <GalleryContext.Provider value={galleryContextData}>{children}</GalleryContext.Provider>;
};

GalleryProvider.propTypes = {
  children: node.isRequired,
  figures: arrayOf(shape({})),
  pictures: arrayOf(shape({})),
};

export default GalleryProvider;
