import { string, shape, arrayOf, bool, objectOf, elementType, func, node, oneOfType } from 'prop-types';

/**
 * The proptypes for the ComponentRenderer.
 * @type {Object}
 * @property {Array[]} list - The list of components to render.
 * @property {Object} componentsToRender - The components to render.
 * @property {Object} componentWrapperType - The component wrapper type.
 * @property {string} className - The class name to render.
 * @property {string} dataTestId - The data test id to render.
 * @property {Function} handleError - The error handler function.
 * @property {Object} metrics - The metrics object.
 * @property {Object} children - The children object.
 * @property {Object} wrapperConfig - The wrapper config object.
 * @property {Object} childrenConfig - The children config object.
 */
const propTypes = {
  list: arrayOf(
    shape({
      id: string,
      type: string,
      props: shape({}),
      show: bool,
    }),
  ).isRequired,
  componentsToRender: objectOf(elementType),
  componentWrapperType: string,
  className: string,
  dataTestId: string,
  handleError: func,
  metrics: shape({}),
  children: oneOfType([node, string]),
  wrapperConfig: shape({
    tag: string,
    className: string,
    dataTestId: string,
    type: string,
  }),
  childrenConfig: shape({
    type: string,
    props: shape({}),
    text: string,
  }),
};

export default propTypes;
