import classnames from 'classnames';

import { constants } from '../../../../../../../utils/constants';

const { DEVICE_TYPE } = constants;

const figureMapper = (props, statics) => {
  const { index, figure, onClick, overlayAlpha, onFullscreen, zoomRef, zoomOptions } = props;
  const { deviceType, namespace, page: app } = statics;

  const { zoom, provider, id } = figure;

  const zoomSrc = zoom ? zoom.src2x || zoom.src : null;
  const preload = index === 0;
  const pictureZoomOptions = {
    ...zoomOptions,
    enableZoom: !!zoom && deviceType === DEVICE_TYPE.DESKTOP,
  };

  return {
    wrapper: {
      className: classnames(`${namespace}__figure`, { [`${namespace}__figure__with-overlay`]: !!overlayAlpha }),
      otherProps: {},
    },
    figureCommon: figure,
    key: `gallery-figure-${id}`,
    className: `${namespace}__figure__${provider.toLowerCase()}`,
    index,
    zoom: zoomSrc,
    onClick,
    onFullscreen,
    preload,
    lazyload: !preload,
    width: app === 'pdp' ? 410 : 700,
    height: 500,
    fetchPriority: preload ? 'high' : null,
    overlayAlpha,
    overlayProps: {
      className: `${namespace}__overlay`,
      color: 'black',
      overlayAlpha,
      app,
    },
    zoomConfig: { container: zoomRef, pciture: id, options: pictureZoomOptions },
    provider,
    otherProps: {},
  };
};

export default figureMapper;
