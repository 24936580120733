import React from 'react';
import { bool, number, string } from 'prop-types';
import { Image as NordicImage } from 'nordic/image';

const Image = props => {
  const { id, src, alt, lazyload, width, height, className, decoding, wrapperClassName } = props;

  return (
    <div className={wrapperClassName}>
      <NordicImage
        id={id}
        src={src}
        alt={alt}
        lazyload={lazyload}
        decoding={decoding}
        width={width}
        height={height}
        className={className}
      />
    </div>
  );
};

Image.propTypes = {
  id: string,
  src: string,
  alt: string,
  lazyload: bool,
  decoding: string,
  width: number,
  height: number,
  className: string,
  wrapperClassName: string,
};

export default Image;
