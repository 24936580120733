const imageMapper = (props, statics) => {
  const { logo } = props;
  const { namespace } = statics;

  return {
    src: logo.url?.src,
    alt: logo.alt,
    lazyload: false,
    width: 64,
    height: 64,
    className: `${namespace}__logo`,
    wrapperClassName: `${namespace}__logo-container`,
  };
};

export default imageMapper;
