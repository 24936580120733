import { PROVIDER } from '../../../../../../figure/constants';

const lightboxMapper = props => {
  const { figures, clipsRef, position, clipsInfo, utils } = props;
  const { mergePictures } = utils;

  return {
    pictures: mergePictures(figures.filter(figure => figure.provider === PROVIDER.CLIP)),
    onTouch: (...params) => clipsRef?.current && clipsRef?.current?.handleTouch(...params),
    position: { ...position, ...clipsInfo.position },
  };
};

export default lightboxMapper;
