const productClasificationMapper = (props, statics) => {
  const { reviews } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: `${namespace}__product-classification`,
      },
    },
    reviews,
  };
};

export default productClasificationMapper;
