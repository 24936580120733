const getFormattedShare = ({ share, show_share }) =>
  share && share.state !== 'HIDDEN' && share.action && show_share
    ? {
        action: share.action,
        permalink: share.permalink,
        title: share.share_title,
        shareActions: share.share_actions,
      }
    : null;

export default getFormattedShare;
