import { useEffect, useState } from 'react';

function usePreviousWithState(value) {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return state;
}

export default usePreviousWithState;
