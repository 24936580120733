import { string, shape } from 'prop-types';
import Script from 'nordic/script';
import { LazyHydrate as Hydrator } from 'nordic/lazy';

import StoreHeader from '../../features/store-header/store-header';
import Gallery from '../../features/gallery/gallery.desktop';
import GalleryOctopus from '../../features/gallery-with-plugins/gallery.desktop';
import Onboarding from '../../features/onboarding/onboarding.desktop';
import CPG from '../../features/cpg/header-cpg.desktop';
import CardWithThumbnails from '../../features/card-with-thumbnails/card-with-thumbnails';

// Head Section
import RelatedSearches from '../../features/related-searches';
import CollapsableCardMeliPlus from '../../features/collapsable-card-meliplus/collapsable-card-meliplus';
import ItemStatusHead from '../../features/item-status/head';
import ItemStatusHeadCompats from '../../features/item-status/head.compats.desktop';
import RedirectFromStatus from '../../features/item-status/redirected-from';
import RecommendationsListHorizontal from '../../features/recommendations/list-horizontal';
import RecommendationsCarouselSnappedHorizontalNeighbor from '../../features/recommendations/carousel-snapped-horizontal-neighbor';
import RecommendationsCarouselSnappedHorizontal from '../../features/recommendations/carousel-snapped-horizontal';
import GroupedHeader from './sections/grouped-header';
import CompatsWidgetSticky from '../../features/compats-widget-sticky/compats-widget-sticky.desktop';
import Compats from '../../features/compats-widget/compats-widget.desktop';
import InstallationService from '../../features/instalation-service';

// Short Description
import Bookmark from '../../features/bookmark';

import Header from '../../features/header/header.desktop';
import HeaderOctopus from '../../features/header-with-plugins/header.desktop';
import Price from '../../features/price/price.desktop';
import Highlights from '../../features/highlights/highlights';
import Promotions from '../../features/promotions/promotions';
import PromotionTop from '../../features/promotions-top';
import PromotionBottom from '../../features/promotions-bottom';
import PaymentSummary from '../../features/payment-summary/payment-summary.desktop';
import CpgSummary from '../../features/cpg/summary.desktop';
import CbtSummary from '../../../../components/cbt/cbt-summary.desktop';
import ShippingSummary from '../../features/shipping-summary/shipping-summary.desktop';
import MeliplusLabel from '../../features/meliplus-label/meliplus-label';
import MeliplusSummary from '../../features/meliplus-summary/meliplus-summary.desktop';
import UpsellingMeliplusSummary from '../../features/upselling-meliplus-summary/upselling-meliplus-summary.desktop';
import UpsellingMeliplusCashback from '../../features/upselling-meliplus-cashback/upselling-meliplus-cashback.desktop';
import CouponSummary from '../../features/coupon-summary/coupon-summary.desktop';
import MoqInformation from '../../features/moq-information/moq-information.desktop';
import StickyWrapper from '../../features/sticky-wrapper';
import SecondShippingSummary from '../../features/second-shipping-summary/second-shipping-summary.desktop';
import SpecialShippingSummary from '../../features/special-shipping-summary/special-shipping-summary.desktop';
import PickUpSummary from '../../features/pick-up-summary/pick-up-summary.desktop';
import CbtTaxesSummary from '../../../../components/cbt-taxes/cbt-taxes-summary.desktop';
import CbtTaxesFcUsSummary from '../../../../components/cbt-taxes-fc-us/cbt-taxes-fc-us-summary.desktop';
import ReturnsSummary from '../../features/returns-summary/returns-summary.desktop';
import VariationsBox from '../../features/variations/variations-box.desktop';
import Variations from '../../features/variations/variations.desktop';
import ItemStatusShortDescription from '../../features/item-status/short-description';
import Seller from '../../features/seller/seller.desktop';
import Reputation from '../../features/seller-reputation-info';
import SellerExperiment from '../../../vpp/features/seller-info/seller-experiment.desktop';
import SellerInfo from '../../features/seller-info/seller-info.desktop';
import SellerData from '../../features/seller-data';
import MerchEcosystem from '../../features/merch-ecosystem/merch-ecosystem.desktop';
import StockInformation from '../../features/stock-information/stock-information.desktop';
import FulfillmentInformation from '../../features/fulfillment-information/fulfillment-information';
import PaypalTooltip from '../../features/tooltips/paypal-tooltip';
import TopAvailableQuantitySummary from '../../features/top-available-quantity-summary/top-available-quantity-summary.desktop';
import BulkSaleCalculator from '../../features/calculator/calculator';
import AvailableQuantity from '../../features/available-quantity/available-quantity.desktop';
import MainActions from '../../features/main-actions/main-actions.desktop';
import CartActions from '../../features/cart-actions/cart-actions.desktop';
import CreditsTooltip from '../../features/tooltips/credits-tooltip';
import ShippingCartBenefitsTooltip from '../../features/tooltips/shipping-cart-benefits-tooltip';
import LoyaltyTooltip from '../../features/tooltips/loyalty-tooltip';
import BuyBenefits from '../../features/buy-benefits/buy-benefits.desktop';
import HeaderTopBrand from '../../features/header-top-brand/header-description.desktop';
import HeaderTopBrandFixed from '../../features/header-top-brand/header-top-brand.desktop';
import GenericBanner from '../../features/generic-banner/index';
import GenericBannerPrice from '../../features/generic-banner-price/index';
import GenericBannerInstallments from '../../features/generic-banner-installments/index';
import ReturnsNotificationBottom from '../../features/returns-notification-bottom/returns-notification-bottom.desktop';
import ReturnsNotificationUp from '../../features/returns-notification-up/returns-notification-up.desktop';

import PricePerQuantityShortcuts from '../../features/price-per-quantity-shortcuts';
import PricePerQuantityCoachmarks from '../../features/price-per-quantity-coachmarks';
// Content Left

import RecommendationsCombos from '../../../../components/combos/combos.desktop';
import Compatibilities from '../../features/compatibilities/compatibilities.desktop';
import TechnicalSpecifications from '../../features/technical-specifications/technical-specifications.desktop';
import Description from '../../features/description/description.desktop';
import ApparelSizeSpecsPreview from '../../features/apparel-size-specs-preview/apparel-size-specs-preview.desktop';
import QADB from '../../features/qadb';
import Questions from '../../features/questions/questions.desktop';
import Reviews from '../../features/reviews/reviews.desktop';
import ReviewsCapability from '../../../../components/reviews-capability/reviews.desktop';
import HighlightedSpecsAttrs from '../../features/highlighted-specs/highlighted-specs-attrs.desktop';
import ProductSuggestions from '../../features/product-suggestions';

import { spotMiddleDesktopRender } from '../../../../utils/spotMiddleRenders';
// Content Right

import Warranty from '../../features/warranty/warranty.desktop';
import Returns from '../../features/returns/returns.desktop';
import PaymentMethods from '../../features/payment-methods/payment-methods.desktop';
import RecommendationsListPads from '../../features/recommendations/list-pads';
import RecommendationsShops from '../../features/recommendations/shops';
import BillboardAd from '../../features/billboard-ad';

import HeaderBrandLogo from '../../features/header-brand-logo/header-brand-logo.desktop';
// Content Bottom
import Denounce from '../../features/denounce';
import OfficialStores from '../../features/official-stores/official-stores.desktop';
import BillboardAdn from '../../features/billboard-adn/billboard-adn-bottom';
import BillboardAdnUp from '../../features/billboard-adn/billboard-adn-up';

import ExtendedMenu from '../../features/extended-menu/extended-menu.desktop';
// Preload Iframe

import PreloadIframe from '../../features/preload-iframe/preload-iframe';
// On Demand Iframe

import OnDemandIframe from '../../features/on-demand-iframe/on-demand-iframe';
// Recommendations

import RecommendationsCarouselSnapped from '../../features/recommendations/carousel-snapped';
// General
import View from '../view';
import Snackbar from '../../features/snackbar';
import DynamicComponentWrapper from '../../features/dynamic-component-wrapper';
import renderSwapComponent from '../../features/dynamic-component-wrapper/render-swap-component';
import GroupedShareBookmark from '../../features/grouped-share-bookmark';

import GiftRegistry from '../../features/gift-registry';
import Grid from '../../../../components/grid';

// Exit event

import ExitPopup from '../../features/exit-popup/exit-popup';
// Utils
import {
  isOfficialStoreHeaderVisible,
  isPaymentMethodsVisible,
  isSellerInfoVisible,
  isSellerDataVisible,
} from '../../../../utils/component-visibility';

import { constants } from '../../../../utils/constants';
import experimentSelectComponent from '../../../../utils/experiment-select-component';

const MeliDeliveryDaySummary = () => (
  <div className="mb-12">
    <MeliplusSummary />
    <CardWithThumbnails className="mb-20 mt-4" />
  </div>
);
// Dynamic Shipping Wrapper (for order swap)
const renderTopShipping = renderSwapComponent('meliplus_summary', <MeliDeliveryDaySummary />, <ShippingSummary />);

const { VISIBILITY } = constants;

const CoreDesktop = props => {
  const { siteId, csrfToken, initialState, currentUser, country } = props;
  const { components = {} } = initialState;
  const hasOfficialStoreHeader = isOfficialStoreHeaderVisible(components);
  const hasPaymentMethods = isPaymentMethodsVisible(components);
  const hasSellerInfo = isSellerInfoVisible(components);
  const hasSellerData = isSellerDataVisible(components);
  const hasCompatsWidgetSticky = components?.compats_widget;
  const hasPricePerQuantityShortcuts =
    components?.price_per_quantity_shortcuts && components?.price_per_quantity_shortcuts.state !== VISIBILITY.HIDDEN;
  const userNickname = currentUser?.nickname || '';
  const { header_exp, gallery_exp } = initialState?.frontEndExperiments ?? {};
  const SelectedHeader = experimentSelectComponent(HeaderOctopus, Header, header_exp?.value);
  const SelectedGallery = experimentSelectComponent(GalleryOctopus, Gallery, gallery_exp?.value);

  return (
    <View {...props} vertical="core">
      <ExitPopup url={initialState.baseUrl} />
      <OnDemandIframe />
      <PreloadIframe />
      <Onboarding />
      <CPG />
      <Grid.Container className="ui-pdp-container--top">
        <Grid.Row>
          <Grid.Col className="ui-vip-core-container--head">
            {!hasOfficialStoreHeader && <RelatedSearches />}
            <RedirectFromStatus />
            <ItemStatusHead />
            <RecommendationsListHorizontal
              featureName="carousel_cheaper"
              className="pt-12 pb-4 pl-0 pr-0"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={3}
            />
            <RecommendationsCarouselSnapped
              featureName="carousel_v2p_not_active_item"
              className="mb-24 mt-24"
              lazy={false}
              preload
              fetchPriority="high"
              criticalImages={5}
            />
            <RecommendationsCarouselSnappedHorizontalNeighbor
              featureName="carousel_neighbour_top"
              className="pt-12 pb-4 pl-0 pr-0 carousel-snapped-horizontal"
              lazy={false}
              preload
              fetchPriority="high"
            />
            <GroupedHeader />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <Grid.Container>
        <StoreHeader />
        <HeaderTopBrandFixed />
      </Grid.Container>
      <CompatsWidgetSticky />
      <ItemStatusHeadCompats />
      <div id="loading" />
      <Grid.Container className="ui-pdp-container--pdp">
        <Grid.Row>
          <HeaderBrandLogo />
        </Grid.Row>
        <Grid.Row id="ui-pdp-main-container" className="ui-pdp-container__row--reverse ui-pdp--relative  pb-40">
          <Grid.Col className="ui-pdp-container--column-right mt-24 pr-24 ui-pdp--relative">
            <StickyWrapper columnName="right">
              <Grid.Row className="ui-pdp-component-list pr-16 pl-16">
                <Grid.Col
                  size={2}
                  className="ui-vip-core-container--short-description ui-vip-core-container--column__right"
                >
                  <HeaderTopBrand />
                  <SelectedHeader>
                    <Highlights className="ui-pdp-highlights" />
                    <Bookmark showLabel={false} csrfToken={csrfToken} />
                  </SelectedHeader>

                  <PromotionTop className="mt-16" />
                  <CollapsableCardMeliPlus />
                  {/* TODO-ERRORBOUNDARY: Esto es una lib --vpp-fe-components-- */}

                  <Price />
                  {hasPricePerQuantityShortcuts && (
                    <div className="mt-20">
                      <PricePerQuantityShortcuts locale={country?.locale} />
                    </div>
                  )}
                  <PromotionBottom className="ui-pdp-highlights mt-24" />

                  <Promotions className="ui-pdp-highlights mt-12" />
                  <CbtTaxesFcUsSummary />
                  <PaymentSummary />
                  <CpgSummary />
                  <CbtSummary />
                  <CouponSummary />
                  <GenericBannerPrice className="mt-16" height={82} width={318} />
                  <GenericBannerInstallments className="mt-16" height={82} width={318} />
                  <div className="ui-pdp-container__row--shipping-chunk mt-24">
                    <MeliplusLabel className="ui-pdp-meliplus-label" />
                    <SpecialShippingSummary />
                    <UpsellingMeliplusCashback />
                    <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
                    <SecondShippingSummary />
                    <UpsellingMeliplusSummary />
                    <GenericBanner className="mt-16" height={82} width={318} />
                    <PickUpSummary />
                    <CbtTaxesSummary />
                    <ReturnsSummary />
                  </div>
                  <VariationsBox className="mt-24" />
                  <Variations />
                  <ItemStatusShortDescription />
                  <InstallationService />
                  <Seller />
                  <Compats default />
                  <div className="ui-pdp-container__row--stock-and-full">
                    <StockInformation />

                    <FulfillmentInformation />
                  </div>

                  <TopAvailableQuantitySummary />

                  <BulkSaleCalculator />
                  <AvailableQuantity />
                  <MoqInformation />
                  <PaypalTooltip />
                  <ReturnsNotificationUp />
                  <MainActions />
                  <ReturnsNotificationBottom />
                  <CartActions />
                  <CreditsTooltip />
                  <ShippingCartBenefitsTooltip />
                  <LoyaltyTooltip />
                  <Reputation />
                  <SellerExperiment className="mb-20 mt-24" />
                  <BuyBenefits />
                  <GiftRegistry className="mt-24" />
                  <GroupedShareBookmark className="mt-24" showOnlyForWishlistVariant />
                </Grid.Col>
              </Grid.Row>
              {hasSellerInfo && (
                <Grid.Row className="ui-pdp-component-list ui-vip-content-right mt-16">
                  <SellerInfo />
                </Grid.Row>
              )}
              {hasSellerData && (
                <Grid.Row className="ui-pdp-component-list ui-vip-content-right mt-16">
                  <SellerData className="ui-box-component ui-box-component-seller-data" />
                </Grid.Row>
              )}
              {MerchEcosystem && (
                <Grid.Row className="ui-vpp-merch-ecosystem-container">
                  <MerchEcosystem className="mt-16" />
                </Grid.Row>
              )}
              {hasPaymentMethods && (
                <Grid.Row className="ui-pdp-component-list ui-vip-content-right mt-16">
                  <Returns />

                  <Warranty />

                  <PaymentMethods />
                </Grid.Row>
              )}
              <Grid.Row className="ui-pdp-component-list ui-vip-content-right mt-16">
                <RecommendationsListPads
                  featureName="advertising_vip_right"
                  className="ui-box-component ui-box-component--block"
                />
              </Grid.Row>
              <Grid.Row className="ui-vip-content-right mt-16">
                <RecommendationsShops featureName="advertising_vip_right_mshops" layout="vip" />
              </Grid.Row>
              <Grid.Row>
                <Hydrator ssrOnly>
                  <BillboardAdnUp />
                </Hydrator>
              </Grid.Row>
            </StickyWrapper>
          </Grid.Col>
          <Grid.Col className="ui-pdp-container--column-left pb-40" size={2}>
            <Grid.Row>
              <Grid.Col>
                <ProductSuggestions className="mt-24 mr-8 ml-24" />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col size={2}>
                <SelectedGallery />
              </Grid.Col>
            </Grid.Row>
            <Grid.Col className="ui-vip-core-container--content-left">
              <RecommendationsCombos className="ui-pdp-combos" />

              <RecommendationsCarouselSnapped
                featureName="carousel_up_web"
                className="ui-pdp-carousel-snapped-up"
                slidesPerView={3}
              />

              <RecommendationsCarouselSnappedHorizontal
                featureName="carousel_above_swap"
                className="ui-pdp-carousel-snapped-up"
                slidesPerView={2}
                arrowsSize="small"
              />

              <RecommendationsCarouselSnapped
                featureName="carousel_above"
                className="ui-pdp-carousel-snapped-up"
                slidesPerView={3}
              />

              <RecommendationsCarouselSnapped
                featureName="carousel_above_pads"
                className="ui-pdp-carousel-snapped-up"
                slidesPerView={3}
              />
              <Compatibilities />
              <TechnicalSpecifications />
              <ApparelSizeSpecsPreview />
              <HighlightedSpecsAttrs />

              <Description />
              <DynamicComponentWrapper
                renderDynamicComponent={spotMiddleDesktopRender({
                  firstChildClassName: 'ui-pdp-with--separator-top ui-pdp-with--separator-top--full mb-40',
                  lastChildClassName: '',
                  middleChildClassName: 'mt-40 mb-40',
                  slidesPerView: 3,
                })}
                className="ui-vip-core-spot-middle"
                spot="spot_middle"
              />
              <div>
                <QADB />
                <Questions />
              </div>
              <Grid.Col size={3}>
                <Reviews />
                <ReviewsCapability />
              </Grid.Col>
              <Grid.Col size={3}>
                <RecommendationsCarouselSnapped
                  featureName="carousel_vis_cross_selling_acc"
                  className="ui-pdp-vis-carousel-b2c"
                  slidesPerView={3}
                  lazy={false}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <OfficialStores />
        </Grid.Row>
      </Grid.Container>
      <Grid.Container className="ui-vip-core ui-pdp-container--bottom">
        <Denounce />
        <DynamicComponentWrapper
          renderDynamicComponent={featureName => (
            <RecommendationsCarouselSnapped className="mb-30 mt-20" featureName={featureName} />
          )}
          spot="carousel_bottom"
        />
        <BillboardAd />
        <BillboardAdn />
        {hasOfficialStoreHeader && <RelatedSearches />}
        <Grid.Row>
          <Grid.Col size={3}>
            <ExtendedMenu />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      {siteId === 'MLB' && <Script src="block-extensions.js" inline />}
      <Snackbar />
      {!hasCompatsWidgetSticky && <PricePerQuantityCoachmarks userNickname={userNickname} />}
    </View>
  );
};

CoreDesktop.propTypes = {
  siteId: string.isRequired,
  csrfToken: string,
  initialState: shape({ baseUrl: string, frontEndExperiments: shape() }),
  country: shape({ locale: string }),
  currentUser: shape({ nickname: string }),
};

export default CoreDesktop;
