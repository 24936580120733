/**
 * Change snake_case to PascalCase
 * @function snakeToPascal
 * @param {string} str
 * @returns {string}
 */
const snakeToPascal = str =>
  str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

/**
 * Change kebab-case to PascalCase
 * @function kebabToPascal
 * @param {string} str
 * @returns {string}
 */
const kebabToPascal = str =>
  str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

export { snakeToPascal, kebabToPascal };
