/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { ComponentRenderer } from '../../../../../../../utils/with-plugins';
import useZoomOptions from './use-zoom-options.hook';

const Main = props => {
  const { namespace, list, componentsToRender, handleError, metrics, wrapperConfig, app } = props;

  const zoomRef = useRef();
  const [modalVisibility, setModalVisibility] = useState(false);
  const { zoomOptions, galleryOptions } = useZoomOptions(zoomRef, namespace, app);

  return (
    <ComponentRenderer
      list={list}
      componentsToRender={componentsToRender}
      handleError={handleError}
      metrics={metrics}
      wrapperConfig={wrapperConfig}
      calculatedProps={{
        modalVisibility,
        setModalVisibility,
        zoomRef,
        zoomOptions,
        galleryOptions,
      }}
    />
  );
};

export default Main;
