const PROVIDER = {
  IMAGE: 'image',
  YOUTUBE: 'youtube',
  CLIP: 'clip',
  MATTERPORT: 'matterport',
  SPINCAR: 'spincar',
};

const CORE_PROVIDERS = [PROVIDER.IMAGE, PROVIDER.YOUTUBE];
const MULTIMEDIA_PROVIDERS = [PROVIDER.MATTERPORT, PROVIDER.SPINCAR, PROVIDER.YOUTUBE];
const VIRTUAL_TOUR_PROVIDERS = [PROVIDER.MATTERPORT, PROVIDER.SPINCAR];

export { PROVIDER, CORE_PROVIDERS, MULTIMEDIA_PROVIDERS, VIRTUAL_TOUR_PROVIDERS };
