import classNames from 'classnames';

const subtitleMapper = (props, statics) => {
  const { subtitle, link_label } = props;
  const { namespace } = statics;
  return {
    wrapper: {
      className: `${namespace}__subtitle`,
    },
    className: classNames('ui-pdp-subtitle', {
      'ui-pdp-subtitle_rex': link_label?.target === 'location_and_points',
    }),
    subtitle,
    link_label,
  };
};

export default subtitleMapper;
