import classnames from 'classnames';

import { PROVIDER } from '../../../../../../figure/constants';

const pictureMapper = (props, statics) => {
  const { positionIndex, id, item, maxHeight, minHeight, galleryType, onTapFigure } = props;
  const { namespace } = statics;

  const { width, height, src, a11yLabel, ...restItemProps } = item;
  const preload = positionIndex === 0;
  const isHorizontal = width > height;
  const isVertical = height > width;
  const imageClassnames = classnames({
    [`${namespace}--horizontal`]: isHorizontal,
    [`${namespace}--vertical`]: isVertical,
  });

  return {
    id,
    dataTestId: `gallery-picture-${positionIndex}`,
    href: src,
    className: `${PROVIDER.IMAGE} ${namespace}--${galleryType}`,
    style: { maxHeight, minHeight },
    arialabel: a11yLabel,
    onClick: onTapFigure,
    imageProps: {
      alt: '',
      id,
      src,
      lazyload: !preload,
      preload,
      width: 500,
      height: 500,
      className: imageClassnames,
      fetchPriority: preload ? 'high' : null,
      ...restItemProps,
    },
  };
};

export default pictureMapper;
