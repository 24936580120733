import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import GalleryContext from '../../../../../context';
import lightboxMapper from './lightbox.mapper';
import Lightbox from '../../../../../../lightbox';

export default {
  [COMPONENT_TYPES.LIGHTBOX]: {
    context: GalleryContext,
    mappers: [lightboxMapper],
    component: Lightbox,
  },
};
