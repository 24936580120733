import { PROVIDER } from '../../figure/constants';

const getFormattedClips = clips =>
  clips?.shorts &&
  clips.shorts.map(short => {
    const { thumbnail } = short;

    return {
      ...short,
      provider: PROVIDER.CLIP,
      thumbnail: thumbnail?.url?.src
        ? {
            src: thumbnail?.url?.src,
            src2x: thumbnail?.url?.src,
            alt: 'Gallery clip thumbnail',
          }
        : thumbnail,
    };
  });

export default getFormattedClips;
