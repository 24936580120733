import React from 'react';
import { shape, string } from 'prop-types';
import { componentEnhance } from '@vpp-frontend-components/component-enhance';

const Label = props => {
  const { wrapper, className, label } = props;
  return (
    <div className={wrapper.className}>
      <span className={className}>{componentEnhance.jsx(label.text, label.values)}</span>
    </div>
  );
};

Label.propTypes = {
  className: string,
  label: string,
  wrapper: shape({ className: string }),
};

export default Label;
