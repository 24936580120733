const isEmpty = obj => !obj || Object.keys(obj)?.length === 0;

const getClipsConfig = clips => {
  if (isEmpty(clips)) {
    return {
      hasClips: false,
    };
  }
  const { autoplay, shorts, snackbar_own_error, snackbar_connection_error } = clips || {};
  const snackbars = [snackbar_own_error, snackbar_connection_error].map(snackbar => {
    const { type, message, delay } = snackbar || {};
    return { id: type, message, delay };
  });

  return {
    autoplay,
    hasClips: !!shorts.length,
    size: shorts.length,
    snackbars,
  };
};

export default getClipsConfig;
