const COMPONENT_TYPES = {
  PILL: 'pill',
  SUBTITLE: 'subtitle',
  TITLE: 'title',
  HIGHLIGHTS: 'highlights',
  BOTTOM_SUBTITLE: 'bottom-subtitle',
  LINK_LABEL: 'link-label',
  COMPATS: 'compats',
  COMPATS_TITLE: 'compats-title',
  TITLE_CONTAINER: 'title-container',
  LABEL: 'label',
  CONTAINER: 'container',
  PRODUCT_STATE: 'product-state',
  PRODUCT_CLASIFICATION: 'product-clasification',
  CHILDREN: 'children',
  ACTION_CONTAINER: 'action-container',
  ACTION: 'action',
};

export { COMPONENT_TYPES };
