const labelMapper = (props, statics) => {
  const { label } = props;
  const { namespace } = statics;
  return {
    wrapper: {
      className: `${namespace}__subtitle`,
    },
    className: 'ui-pdp-subtitle',
    label,
  };
};

export default labelMapper;
