import { REVIEWS_COMPONENT_TYPES } from '../../../../../constants';

const mainMapper = (props, statics) => {
  const { list, reviews, reviewsIframeId } = props;
  const { namespace } = statics;
  return {
    list: [
      ...list,
      {
        wrapperConfig: {
          tag: 'div',
          props: {
            className: `${namespace}__info`,
          },
        },
        type: REVIEWS_COMPONENT_TYPES.REVIEWS_SUMMARY,
        props: {
          reviews,
          reviewsIframeId,
        },
        show: !!reviews,
      },
    ],
  };
};

export default mainMapper;
