import galleryColumnMapper from './gallery-column.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { ComponentRenderer } from '../../../../../../../utils/with-plugins';

export default {
  [COMPONENT_TYPES.GALLERY_COLUMN]: {
    mappers: [galleryColumnMapper],
    component: ComponentRenderer,
  },
};
