import { arrayIncludes } from '../../../lib/includes';

const galleryTypes = ['square', 'horizontal', 'vertical'];

const getGalleryType = ({ gallery_type, pictures }) => {
  const firstGalleryPicture = pictures?.[0] || null;
  if (gallery_type && arrayIncludes(galleryTypes, gallery_type)) {
    return gallery_type;
  }
  if (firstGalleryPicture) {
    if (firstGalleryPicture.width === firstGalleryPicture.height) {
      return 'square';
    }
    if (firstGalleryPicture.width > firstGalleryPicture.height) {
      return 'horizontal';
    }
  }
  return null;
};

export default getGalleryType;
