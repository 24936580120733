/* eslint-disable react/prop-types */
import MAIN from './main';
import CLIPS_WORKER from './clips-worker';
import FIGURE from './figure';
import GALLERY_COLUMN from './gallery-column';
import GALLERY_SLIDE from './gallery-slide';
import LIGHTBOX from './lightbox';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const ClipsPlugin = props => {
  const { clipsConfig } = props;

  if (!clipsConfig?.hasClips) {
    return null;
  }

  return {
    ...MAIN,
    ...CLIPS_WORKER,
    ...FIGURE,
    ...GALLERY_COLUMN,
    ...GALLERY_SLIDE,
    ...LIGHTBOX,
  };
};

ClipsPlugin.ownership = OWNERSHIPS.VPP;
ClipsPlugin.id = 'clips';

export default ClipsPlugin;
