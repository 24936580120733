import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const headerContainerMapper = (props, statics) => {
  const { tag, subtitle, label, reviews } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: `${namespace}__container`,
      },
    },
    list: [
      {
        type: COMPONENT_TYPES.PRODUCT_STATE,
        props: {
          tag,
          subtitle,
          label,
        },
      },
      {
        type: COMPONENT_TYPES.PRODUCT_CLASIFICATION,
        props: {
          reviews,
        },
      },
    ],
  };
};

export default headerContainerMapper;
