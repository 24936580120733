import { OWNERSHIPS } from '../../../../../../utils/with-plugins';
import MAIN_COMPONENT from './main';
import OFFICIAL_STORE from './official-store';

const officialStore = props => {
  const { official_store } = props;
  if (!official_store) {
    return null;
  }
  return {
    ...MAIN_COMPONENT,
    ...OFFICIAL_STORE,
  };
};

officialStore.ownership = OWNERSHIPS.VPP;
officialStore.id = 'official-store';

export default officialStore;
