import React from 'react';
import { node } from 'prop-types';

const Children = props => {
  const { children } = props;
  return <>{children}</>;
};

Children.propTypes = {
  children: node,
};

export default Children;
