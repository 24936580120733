const buildContextDefinition = ({ def, acc, id, isCore, pluginId, ownership }) => {
  // context
  const currentContext = def?.context;
  const accContext = acc?.context;

  const accContextData = acc?.contextData;
  const currentContextData = currentContext && {
    name: currentContext?.customName || currentContext?.name || 'ReactContext',
    id,
    isCore,
    ownership,
    pluginId,
  };

  const contextData = accContextData || currentContextData || null;

  if (accContext && currentContext) {
    const responsiblePlugin = accContextData?.pluginId || `of ${accContextData}`;
    throw new Error(
      `The context of ${id} has already been defined in the plugin ${responsiblePlugin}. 
      Context's cannot be overwritten.\nError in ${pluginId.toUpperCase()}/.../${id}/index.js`,
    );
  }

  return {
    context: currentContext || accContext || null,
    contextData,
  };
};

export default buildContextDefinition;
