import clipsWorkerMapper from './clips-worker.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import GalleryContext from '../../../../../context';
import { loadable } from 'nordic/lazy';

const ClipsWorker = loadable(() => import('./clips-worker.ui'));

export default {
  [COMPONENT_TYPES.CLIPS_WORKER]: {
    context: GalleryContext,
    mappers: [clipsWorkerMapper],
    component: ClipsWorker,
  },
};
