export const MAIN_TYPE = 'main';

/**
 * DECORATION_TYPES - The decoration types.
 * @constant
 * @type {Object}
 */
const DECORATION_TYPES = {
  MAPPER: 'mapper',
  COMPONENT: 'component',
  HOC: 'hoc',
  HELPER: 'helper',
  PLUGIN: 'plugin',
  DEFINITION: 'definition',
};

/**
 * POSITION - The positions of component.
 * @constant
 * @type {Object}
 */
const POSITION = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
};

/**
 * OWNERSHIPS - The ownerships for plugins.
 * @constant
 * @type {Object}
 */
const OWNERSHIPS = {
  VPP: 'vpp',
  APPAREL: 'apparel',
  REFURBISHED: 'refurbished',
  MSHOPS: 'mshops',
  REVIEWS: 'reviews',
  COMPATS: 'compats',
};

Object.freeze(DECORATION_TYPES);
Object.freeze(POSITION);
Object.freeze(OWNERSHIPS);

export { DECORATION_TYPES, POSITION, OWNERSHIPS };
