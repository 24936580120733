import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import * as actions from '../../../../actions/vip/vis';

const FEATURE_NAME = 'header-octopus';
const HEADER = 'header';
const namespace = 'ui-pdp-header';

const mapStateToProps = ({ components: { [HEADER]: header }, id, history }, ownProps) => ({
  ...header,
  ...ownProps,
  reviewsIframeId: history && history.parent_product_id ? history.parent_product_id : id,
});

const mapDispatchToProps = dispatch => ({
  onScrollComponent: ({ component, focus }) => dispatch(actions.onScrollComponent({ component, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: 'components.header' }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const component = getComponent => enhance(getComponent(FEATURE_NAME, namespace));

export default component;
