import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import classnames from 'classnames';

const mainMapper = (props, statics) => {
  const {
    tag,
    label,
    link_label,
    subtitle,
    title,
    children,
    bottom_subtitle,
    onScrollComponent,
    action,
    className,
    reviews,
  } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: classnames(namespace, 'pt-16 pl-16 pr-16', className),
      },
    },
    list: [
      {
        type: COMPONENT_TYPES.CONTAINER,
        props: {
          tag,
          subtitle,
          label,
          reviews,
        },
        show: true,
      },
      {
        type: COMPONENT_TYPES.CHILDREN,
        props: {
          children,
        },
        show: !!children,
      },
      {
        type: COMPONENT_TYPES.TITLE,
        props: {
          title,
        },
        show: !!title,
      },
      {
        type: COMPONENT_TYPES.BOTTOM_SUBTITLE,
        props: {
          bottom_subtitle,
        },
        show: !!bottom_subtitle,
      },
      {
        type: COMPONENT_TYPES.ACTION_CONTAINER,
        props: {
          action,
          onScrollComponent,
        },
        show: !!action,
      },
      {
        type: COMPONENT_TYPES.LINK_LABEL,
        props: {
          link_label,
          onScrollComponent,
        },
        show: !!link_label,
      },
    ],
  };
};

export default mainMapper;
