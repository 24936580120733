import { applyClasses } from '@vpp-frontend-components/common';

const pillMapper = (props, statics) => {
  const { tag } = props;
  const { namespace } = statics;
  return {
    className: applyClasses({ ...tag, className: `${namespace}__tag` }),
    color: 'gray',
    size: 'large',
    hierarchy: 'quiet',
    roundedCorners: ['top-right', 'top-left', 'bottom-right', 'bottom-left'],
    text: tag?.text,
  };
};

export default pillMapper;
