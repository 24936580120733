import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import subtitleMapper from '../../../common/components/subtitle/subtitle.mapper';
import Subtitle from '../../../common/components/subtitle/subtitle.ui';

export default {
  [COMPONENT_TYPES.SUBTITLE]: {
    component: Subtitle,
    mappers: [subtitleMapper],
  },
};
