import ComponentRenderer from '../../../../../../../utils/with-plugins/helpers/component-renderer';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import headerContainerMapper from './header-container.mapper';

export default {
  [COMPONENT_TYPES.CONTAINER]: {
    component: ComponentRenderer,
    mappers: [headerContainerMapper],
  },
};
