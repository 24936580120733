import { OWNERSHIPS } from '../../../../../../utils/with-plugins';
import MAIN_COMPONENT from './main';
import REVIEWS_SUMMARY from './reviews-summary';

const reviews = props => {
  const { reviews: reviewsProps } = props;
  if (!reviewsProps) {
    return null;
  }
  return {
    ...MAIN_COMPONENT,
    ...REVIEWS_SUMMARY,
  };
};

reviews.ownership = OWNERSHIPS.REVIEWS;

export default reviews;
