import APIService from '../services/api';

import {
  FETCH_ERROR as ACTION_FETCH_ERROR,
  FETCH_ON_SET_BOOKMARK_COMPLETE,
  SHOW_SNACKBAR as ACTION_SHOW_SNACKBAR,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  HIDE_SNACKBAR as ACTION_HIDE_SNACKBAR,
} from '../actions/actions';

const COMPONENT_ID = 'bookmark_snackbar';
const CALLED_FROM = 'wishlist_save_button';
const classNameRemoveBookmark = 'snackbar--remove-bookmark';

const onClickAddToList = (dispatch, newDynamicTarget) => {
  dispatch({
    type: WISHLIST_SHOW_BOTTOMSHEET_MODAL,
    payload: {
      targetDynamic: newDynamicTarget,
    },
  });
  dispatch({ type: ACTION_FETCH_ERROR, payload: {} });
  dispatch({ type: ACTION_HIDE_SNACKBAR });
};

const addVariationInfos = (element, gift_registry, isRemoveElement = false) => {
  if (gift_registry?.variation_id) {
    Object.assign(element, {
      entity_data: {
        ...element?.entity_data,
        variation_id: gift_registry?.variation_id,
      },
    });
  }

  if (gift_registry?.selected_attributes && !isRemoveElement) {
    Object.assign(element, {
      entity_data: {
        ...element?.entity_data,
        attributes: gift_registry?.selected_attributes,
      },
    });
  }

  return element;
};

const execAddElementToList = (element, dispatch, listHubInfos, SHOW_SNACKBAR, HIDE_SNACKBAR, FETCH_ERROR) => {
  const { gift_registry } = listHubInfos;

  const elementObject = addVariationInfos(element, gift_registry);

  APIService.addElementToList(elementObject)
    .then(({ component = null }) => {
      if (component?.id === COMPONENT_ID) {
        const newDynamicTarget = component?.action?.target || null;
        const hubUrl = new URL(listHubInfos?.action?.target);
        hubUrl.pathname = newDynamicTarget;
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message: component?.message,
            type: component?.message_type,
            delay: component?.delay?.duration || 3000,
            called_from: CALLED_FROM,
            className: classNameRemoveBookmark,
            action: {
              text: component?.action?.label?.text,
              onClick: () => {
                window.location.assign(hubUrl);
                dispatch({ type: HIDE_SNACKBAR, payload: {} });
              },
            },
          },
        });
      }
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const execRemoveElementFromList = (element, dispatch, listHubInfos, TOGGLE_BOOKMARKED, SHOW_SNACKBAR, FETCH_ERROR) => {
  const { gift_registry } = listHubInfos;
  const elementObject = addVariationInfos(element, gift_registry, true);

  APIService.removeElementFromList(elementObject)
    .then(({ component = null, lists_with_element = null }) => {
      if (lists_with_element === 0) {
        dispatch({
          type: TOGGLE_BOOKMARKED,
        });
      }
      if (component?.id === COMPONENT_ID) {
        const { message, message_type, delay } = component;
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message,
            type: message_type,
            delay: delay?.duration || 3000,
            called_from: CALLED_FROM,
            className: classNameRemoveBookmark,
          },
        });
      }
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const execFetchUpdateWishlist = (dispatch, id, app, UPDATE_GIFT_REGISTRY, forceChecked, FETCH_ERROR) => {
  APIService.getUpdateWishlists(id, { app })
    .then(({ bookmark, components }) => {
      dispatch({
        type: UPDATE_GIFT_REGISTRY,
        payload: {
          bookmark,
          wishlist_save_button: components.wishlist_save_button,
          ...(forceChecked !== null && { check: forceChecked }),
        },
      });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const execRemoveBookmark = (dispatch, itemId, variationId, statusNeutral, statusOk, statusError, calledFrom) => {
  APIService.removeBookmark(itemId, variationId)
    .then(({ snack_bar = null, status: responseStatus = null }) => {
      const snackbar_status = snack_bar?.message_type === statusNeutral ? statusOk : statusError;
      if (snackbar_status === statusOk) {
        dispatch({
          type: UPDATE_GIFT_REGISTRY_CHECKBOX,
          payload: {
            check: false,
          },
        });
      }
      const status = responseStatus || snackbar_status;
      dispatch({ type: FETCH_ON_SET_BOOKMARK_COMPLETE, payload: { status } });
      if (snack_bar) {
        dispatch({
          type: ACTION_SHOW_SNACKBAR,
          params: {
            message: snack_bar?.message,
            type: snack_bar?.message_type,
            delay: snack_bar?.delay?.duration || 3000,
            called_from: calledFrom,
            className: 'snackbar--remove-bookmark',
          },
        });
      }
    })
    .catch(e => dispatch({ type: ACTION_FETCH_ERROR, error: e }));
};

const execAddBookmark = (
  dispatch,
  itemId,
  variationId,
  selectedAttributes,
  csrfToken,
  wishlistSaveButton,
  statusSucess,
  statusOk,
  statusError,
  calledFrom,
) => {
  APIService.addBookmark(itemId, variationId, selectedAttributes, csrfToken, wishlistSaveButton?.gift_registry?.id)
    .then(data => {
      if (data) {
        const { snack_bar, responseStatus } = data;
        const snackbar_status = snack_bar?.message_type === statusSucess ? statusOk : statusError;
        const status = responseStatus || snackbar_status;
        const newDynamicTarget = snack_bar?.action?.target || null;

        dispatch({ type: FETCH_ON_SET_BOOKMARK_COMPLETE, payload: { status } });

        if (snack_bar) {
          dispatch({
            type: ACTION_SHOW_SNACKBAR,
            params: {
              message: snack_bar?.message,
              type: snack_bar.message_type,
              delay: snack_bar?.delay?.duration || 6000,
              called_from: calledFrom,
              className: 'snackbar--add-bookmark',
              action: {
                text: snack_bar?.action?.label?.text,
                onClick: () => onClickAddToList(dispatch, newDynamicTarget),
              },
            },
          });
        }
      }
    })
    .catch(e => dispatch({ type: ACTION_FETCH_ERROR, error: e }));
};

export {
  execAddElementToList,
  execRemoveElementFromList,
  execFetchUpdateWishlist,
  execAddBookmark,
  execRemoveBookmark,
  onClickAddToList,
  addVariationInfos,
};
