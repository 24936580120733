const bookmarkMapper = props => {
  const {
    bookmark,
    onClickBookmark,
    openWishlistModalBS,
    closeWishlistModalBS,
    updateGiftRegistry,
    showSnackbar,
    toggleGiftRegistryCheckbox,
    fetchBookmark,
  } = props;

  return {
    ...bookmark,
    onClick: onClickBookmark,
    openWishlistModalBS,
    closeWishlistModalBS,
    updateGiftRegistry,
    showSnackbar,
    toggleGiftRegistryCheckbox,
    fetchBookmark,
  };
};

export default bookmarkMapper;
