import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Pill as PillUI } from '@andes/badge';

const Pill = ({ text, className, color, size, hierarchy, roundedCorners, otherProps }) => (
  <PillUI
    className={className}
    color={color}
    size={size}
    hierarchy={hierarchy}
    roundedCorners={roundedCorners}
    {...otherProps}
  >
    {text}
  </PillUI>
);

Pill.propTypes = {
  className: string,
  color: string,
  size: string,
  hierarchy: string,
  roundedCorners: arrayOf(string),
  text: string,
  otherProps: shape({}),
};

export default Pill;
