import ComponentRenderer from '../../../../../../../utils/with-plugins/helpers/component-renderer';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import productClasificationMapper from './product-clasification.mapper';

export default {
  [COMPONENT_TYPES.PRODUCT_CLASIFICATION]: {
    component: ComponentRenderer,
    mappers: [productClasificationMapper],
  },
};
