import { COMPONENT_TYPES } from '../../../../../../../components/header-with-plugins';
import { addElementToList, POSITION } from '../../../../../../../utils/with-plugins';

const mainMapper = props => {
  const { list, highlights, title_compats } = props;

  const elementToInsert = {
    type: COMPONENT_TYPES.HIGHLIGHTS,
    props: {
      highlights,
    },
    show: !!highlights,
  };

  const listWithHighlights = addElementToList(list, elementToInsert, POSITION.BEFORE, COMPONENT_TYPES.BOTTOM_SUBTITLE);

  const listWithTitleCompats = listWithHighlights.map(el => {
    if (el.type === COMPONENT_TYPES.TITLE_CONTAINER) {
      return {
        ...el,
        props: {
          ...el.props,
          title_compats,
        },
      };
    }
    return el;
  });

  return {
    list: listWithTitleCompats,
  };
};

export default mainMapper;
