import lightboxMapper from './lightbox.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { loadable } from 'nordic/lazy';
import GalleryContext from '../../../../../context';

const Lightbox = loadable(() => import('../../../../../../lightbox'));

export default {
  [COMPONENT_TYPES.LIGHTBOX]: {
    mappers: [lightboxMapper],
    component: Lightbox,
    context: GalleryContext,
  },
};
