/* eslint-disable react/prop-types */
import MAIN from './main';
import BOOKMARK from './bookmark';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const BookmarkPlugin = props => {
  const { bookmark } = props;

  if (!bookmark) {
    return null;
  }

  return {
    ...MAIN,
    ...BOOKMARK,
  };
};

BookmarkPlugin.ownership = OWNERSHIPS.VPP;
BookmarkPlugin.id = 'bookmarks';

export default BookmarkPlugin;
