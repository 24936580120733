import { DECORATION_TYPES } from '../constants';

const NO_AVAILABLE = 'no available';

/**
 * Try to enhance the mapper function with the ownership and isCore values.
 * @function enhanceMapper
 * @param {string} ownership - The ownership to add to the element.
 * @param {boolean} isCore - The isCore value to add to the element.
 * @param {string} pluginId - The pluginId of the component.
 * @returns {Function} - The function that adds the ownership and isCore values to the element.
 * @param {Function} mapperFn - The mapper function to enhance.
 * @param {string} id - The id of the component.
 * @param {Function} component - The component to add the displayName to.
 * @param {Function} handleError - The error handler function.
 * @returns {Function} - The enhanced mapper function.
 */
const enhanceMapper = (mapperFn, ownership, isCore, id, pluginId, componentData, handleError) => {
  const mapperEnhanced = props => {
    try {
      const { calculatedProps, context, statics, ...input } = props;
      const output = mapperFn(input, statics, calculatedProps, context);
      return { ...input, ...output, calculatedProps, statics, context };
    } catch (error) {
      handleError(error, {
        component: componentData || {
          id,
          name: NO_AVAILABLE,
          ownership: NO_AVAILABLE,
          isCore: NO_AVAILABLE,
          pluginId: NO_AVAILABLE,
        },
        decorationType: DECORATION_TYPES.MAPPER,
        id,
        isCore,
        pluginId,
        name: mapperFn.name,
        ownership,
      });
      return props;
    }
  };
  mapperEnhanced.customName = mapperFn.customName || mapperFn.name;
  mapperEnhanced.ownership = ownership;
  mapperEnhanced.isCore = isCore;
  mapperEnhanced.pluginId = pluginId;
  return mapperEnhanced;
};

const buildMappersDefinition = ({
  def,
  acc,
  id,
  hasComponent,
  componentData,
  handleError,
  isCore,
  pluginId,
  ownership,
}) => {
  const currentMappers = (def?.mappers || []).map(fn =>
    enhanceMapper(fn, ownership, isCore, id, pluginId, componentData, handleError),
  );
  const accMappers = acc?.mappers || [];

  if (hasComponent) {
    return { mappers: [...currentMappers, ...accMappers] };
  }

  return {
    mappers: [...accMappers, ...currentMappers],
  };
};

export default buildMappersDefinition;
export { enhanceMapper };
