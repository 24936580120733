import ComponentRenderer from '../../../../../../../utils/with-plugins/helpers/component-renderer';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import titleContainerMapper from './title-container.mapper';

export default {
  [COMPONENT_TYPES.TITLE_CONTAINER]: {
    component: ComponentRenderer,
    mappers: [titleContainerMapper],
  },
};
