import shareMapper from './share.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import Share from '../../../../../../share/share.mobile';

export default {
  [COMPONENT_TYPES.SHARE]: {
    mappers: [shareMapper],
    component: Share,
  },
};
