import React, { memo, useContext } from 'react';
import { kebabToPascal } from './convert-case';

/**
 * Function that creates a high order component with a custom context.
 * @function withCustomContext
 * @param {Object} context - The custom context to use.
 * @param {string} id - The id of the component.
 * @returns {Function} - The high order component.
 * @param {Object} WrappedComponent - The component to wrap.
 * @returns {React.Element} - The high order component.
 */
const withCustomContext = (context, id) => WrappedComponent => {
  const HighOrderComponent = memo(props => {
    const contextData = useContext(context);
    const propsWithExtras = { ...props, context: contextData };
    return <WrappedComponent {...propsWithExtras} />;
  });

  HighOrderComponent.displayName = `withContext(${kebabToPascal(id)})`;
  return HighOrderComponent;
};

export default withCustomContext;
