import classnames from 'classnames';

import { PROVIDER } from '../../../../../../figure/constants';

const multimediaMapper = (props, statics) => {
  const { id, item, provider, galleryType, onTapFigure } = props;
  const { namespace } = statics;

  const { url, button, showTopInGallery } = item;
  const { content } = button || {};
  const { icon = {}, label = {} } = content || {};

  return {
    key: id,
    href: url,
    dataTestId: 'highlightedMultimedia',
    className: classnames(`${provider || PROVIDER.IMAGE} ${namespace}--${galleryType}`, {
      'show-highlighted-multimedia-at-end': !showTopInGallery,
    }),
    iconWrapperProps: {
      className: `${namespace}__highlighted-multimedia-button`,
    },
    labelProps: {
      as: 'span',
      className: 'ui-pdp-label',
      ...label,
    },
    previewProps: {
      className: `${namespace}__highlighted-multimedia-preview img-highlighted`,
      ...item,
    },
    icon,
    onClick: onTapFigure,
  };
};

export default multimediaMapper;
