import { MAIN_TYPE } from '../constants';

/**
 * Function that adds the displayName to the component.
 * @function addDisplayName
 * @param {Function} component - The component to add the displayName to.
 * @returns {(Function | null)} - The component with the displayName added.
 */
const addDisplayName = component => {
  if (component) {
    component.displayName = component.displayName || component.name;
    return component;
  }

  return null;
};

const buildComponentDefinition = ({ def, acc, id, isCore, WrapperComponent, pluginId, ownership }) => {
  const isMain = id === MAIN_TYPE;
  const mainComponent = isCore && WrapperComponent;
  const currentComponent = addDisplayName(isMain ? mainComponent : def?.component);
  const accComponent = acc?.component;

  const accComponentData = acc?.componentData;
  const currentComponentData = currentComponent && {
    name: currentComponent?.displayName || currentComponent?.name,
    id,
    isCore,
    ownership,
    pluginId,
  };

  const componentData = accComponentData || currentComponentData || null;

  if (accComponent && currentComponent) {
    throw new Error(
      `The ${id} component has already been defined in the plugin ${
        componentData.pluginId
      }. Components cannot be overwritten.\nError in ${pluginId.toUpperCase()}/.../${id}/index.js`,
    );
  }

  return {
    component: accComponent || currentComponent || null,
    componentData,
    hasComponent: !!currentComponent,
  };
};

export default buildComponentDefinition;
export { addDisplayName };
