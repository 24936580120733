import MAIN_COMPONENT from './main';
import TITLE_CONTAINER from './title-container';
import COMPATS_TITLE from './compats-title';
import HIGHLIGHTS from '../../common/components/highlights';
import COMPATSS from '../../common/components/compats';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const compats = props => {
  const { highlights, title_compats } = props;
  if (!highlights && !title_compats) {
    return null;
  }

  return {
    ...MAIN_COMPONENT,
    ...TITLE_CONTAINER,
    ...COMPATS_TITLE,
    ...HIGHLIGHTS,
    ...COMPATSS,
  };
};

compats.ownership = OWNERSHIPS.COMPATS;

export default compats;
