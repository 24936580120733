import MAIN from './main';
import ZOOM from './zoom';
import GALLERY_COLUMN from './gallery-column';
import IMAGE from './image';
import GALLERY_SLIDE from './gallery-slide';
import THUMBNAIL from './thumbnail';
import FIGURE from './figure';

import LIGHTBOX from './lightbox';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const CorePlugin = () => ({
  ...MAIN,
  ...ZOOM,
  ...GALLERY_COLUMN,
  ...IMAGE,
  ...GALLERY_SLIDE,
  ...THUMBNAIL,
  ...FIGURE,
  ...LIGHTBOX,
});

CorePlugin.ownership = OWNERSHIPS.VPP;
CorePlugin.isCore = true;

export default CorePlugin;
