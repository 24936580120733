import buildComponentDefinition from './build-component-definition';
import buildContextDefinition from './build-context-definition';
import buildHocsDefinition from './build-hocs-definition';
import buildMappersDefinition from './build-mappers-definition';

/**
 * Function that builds the definition of the component.
 * @function buildDefinition
 * @param {string} id - The id of the component.
 * @param {Object} def - The definition of the component.
 * @param {Object} acc - The accumulated definition.
 * @param {Object} WrapperComponent - The main component.
 * @param {string} ownership - The ownership of the component.
 * @param {boolean} isCore - The isCore value of the component.
 * @param {string} pluginId - The pluginId of the component.
 * @param {Function} handleError - The error handler function.
 * @returns {Object} - The built definition.
 */
const buildDefinition = (id, def, acc, WrapperComponent, ownership, isCore, pluginId, handleError) => {
  // component
  const { component, hasComponent, componentData } = buildComponentDefinition({
    def,
    acc,
    id,
    isCore,
    WrapperComponent,
    pluginId,
    ownership,
  });
  // context
  const { context, contextData } = buildContextDefinition({
    def,
    acc,
    id,
    isCore,
    pluginId,
    ownership,
  });

  // mappers
  const { mappers } = buildMappersDefinition({
    def,
    acc,
    id,
    hasComponent,
    componentData,
    handleError,
    isCore,
    pluginId,
    ownership,
  });

  // hocs
  const { hocs } = buildHocsDefinition({
    def,
    acc,
    id,
    isCore,
    pluginId,
    ownership,
  });

  return {
    hocs,
    component,
    context,
    mappers,
    componentData,
    contextData,
  };
};

export default buildDefinition;
