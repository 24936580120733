import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import { StyledLabel } from '@vpp-frontend-components/styled-label';
import bottomSubtitleMapper from '../../../common/components/bottom-subtitle/bottom-subtitle.mapper';

export default {
  [COMPONENT_TYPES.BOTTOM_SUBTITLE]: {
    component: StyledLabel,
    mappers: [bottomSubtitleMapper],
  },
};
