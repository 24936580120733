const getFormattedBookmarks = ({ bookmark, id, csrfToken, wishlist }) =>
  bookmark && bookmark?.state !== 'HIDDEN'
    ? {
        isBookmarked: bookmark.is_bookmarked,
        href: `${id}/bookmark?method=${bookmark.isBookmarked ? 'remove' : 'add'}&item_id=${id}`,
        remove: bookmark.delete,
        add: bookmark.add,
        isFetching: bookmark.isFetching,
        themeMode: 'circle',
        itemId: bookmark.item_id,
        csrfToken,
        wishlist: wishlist && {
          label: wishlist?.action?.label?.text,
          title: wishlist?.title,
          target: wishlist?.targetDynamic || wishlist?.action?.target,
          loading_text: wishlist?.action?.loading_text,
          show: wishlist?.show,
          wishlistVariantVisible: wishlist?.state === 'VISIBLE',
          gift_registry: wishlist?.gift_registry || null,
          track: wishlist?.track,
        },
      }
    : null;

export default getFormattedBookmarks;
