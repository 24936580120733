import { PROVIDER } from '../../../../../../figure/constants';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const gallerySlideMapper = props => {
  const { list, setModalVisibility, handleClip, clipsRef, clipsConfig, utils } = props;

  const { handleClick } = utils;

  const getHandle = elProps => {
    const { figure } = elProps;

    if (figure?.provider === PROVIDER.CLIP) {
      return handleClip;
    }

    return handleClick;
  };

  return {
    list: list.map(el => {
      if (el.type === COMPONENT_TYPES.THUMBNAIL) {
        return {
          ...el,
          props: {
            ...el.props,
            onClick: () => setModalVisibility(true),
          },
        };
      }

      if (el.type === COMPONENT_TYPES.FIGURE) {
        return {
          ...el,
          props: {
            ...el.props,
            onClick: getHandle(el.props),
            clipsRef,
            clipsConfig,
          },
        };
      }

      return el;
    }),
  };
};

export default gallerySlideMapper;
