const linkLabelMapper = props => {
  const { link_label, onScrollComponent } = props;
  return {
    title: link_label?.label,
    icon: link_label?.icon,
    target: link_label?.target,
    track: link_label?.track,
    onScrollComponent: link_label?.target ? onScrollComponent : null,
  };
};

export default linkLabelMapper;
