import React from 'react';
import { shape, arrayOf, string, func, number } from 'prop-types';
import CarouselSnapped from '../../../../../../carousel-snapped';
import renderListComponents from '../../../../../../../utils/with-plugins/helpers/render-list-components';

const GalleryCarousel = props => {
  const {
    shapes,
    galleryId,
    accessibility_text,
    app,
    overlayAlpha,
    setCounter,
    counter,
    list,
    componentsToRender,
    handleError,
    metrics,
  } = props;

  return (
    <CarouselSnapped
      key={galleryId}
      counter={counter}
      setCounter={setCounter}
      accessibility_text={accessibility_text}
      app={app}
      overlayAlpha={overlayAlpha}
      shapes={shapes}
    >
      {renderListComponents(list, componentsToRender, {}, null, handleError, metrics)}
    </CarouselSnapped>
  );
};

GalleryCarousel.propTypes = {
  list: arrayOf(shape({})),
  componentsToRender: shape({}),
  shapes: arrayOf(string),
  galleryId: string,
  accessibility_text: string,
  app: string,
  overlayAlpha: string,
  setCounter: func,
  counter: shape({ current: number }),
  handleError: func,
  metrics: shape({}),
};

export default GalleryCarousel;
