import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const mainMapper = props => {
  const { list, share } = props;
  return {
    list: [
      ...list,
      {
        type: COMPONENT_TYPES.SHARE,
        props: {
          share,
        },
        show: !!share,
      },
    ],
  };
};

export default mainMapper;
