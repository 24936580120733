import { trackEvent } from '@vpp-frontend-components/common';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const gallerySlideMapper = (props, statics, _, context) => {
  const {
    figure,
    slidePosition,
    setModalVisibility,
    seeMoreAmount,
    showThumbnail,
    thumbnailPicture,
    track,
    zoomRef,
    zoomOptions,
    title,
    overlayAlpha,
  } = props;

  const { namespace } = statics;
  const { setCurrentIndex, position } = context;

  const { alt, id } = figure;

  const handleClick = () => {
    setModalVisibility(true);
    if (track) {
      trackEvent(track);
    }
  };

  return {
    input: {
      className: `${namespace}__input`,
      type: 'radio',
      name: 'gallery-radio',
      id: `gallery-thumbnail-${id}`,
      value: slidePosition,
      readOnly: true,
      checked: position.current === slidePosition,
    },
    className: `${namespace}__wrapper`,
    list: [
      {
        type: COMPONENT_TYPES.THUMBNAIL,
        props: {
          slidePosition,
          figure,
          alt,
          id,
          picture: thumbnailPicture,
          label: title,
          seeMoreAmount,
          onKeyDown: e => {
            if (e.key === 'Enter') {
              setCurrentIndex(slidePosition);
            }
          },
          onMouseOver: () => setCurrentIndex(slidePosition),
          onClick: (...args) => seeMoreAmount && handleClick(...args),
        },
        show: thumbnailPicture && showThumbnail,
      },
      {
        type: COMPONENT_TYPES.FIGURE,
        props: {
          slidePosition,
          figure,
          onClick: handleClick,
          overlayAlpha,
          onFullscreen: () => {
            setModalVisibility(true);
          },
          zoomRef,
          zoomOptions,
        },
      },
    ],
    utils: {
      handleClick,
    },
  };
};

export default gallerySlideMapper;
