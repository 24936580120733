import bookmarkMapper from './bookmark.mapper';
import Bookmark from '../../../../../../bookmark';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

export default {
  [COMPONENT_TYPES.BOOKMARK]: {
    mappers: [bookmarkMapper],
    component: Bookmark,
  },
};
