import MAIN from './main';
import VIRTUAL_TOUR from './virtual-tour';
import GALLERY_CAROUSEL from './gallery-carousel';
import MULTIMEDIA from './multimedia';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';
import { VIDEO_TYPES } from '../../../../helpers/constants';
import { PROVIDER } from '../../../../../figure/constants';

const visPlugin = props => {
  const { highlightedMultimedia } = props;

  const hasYoutubeVideo = highlightedMultimedia?.provider === PROVIDER.YOUTUBE;
  const hasVirtualTour = Object.values(VIDEO_TYPES).includes(highlightedMultimedia?.video_type);

  if (!hasVirtualTour && !hasYoutubeVideo) {
    return null;
  }

  return {
    ...MAIN,
    ...VIRTUAL_TOUR,
    ...GALLERY_CAROUSEL,
    ...MULTIMEDIA,
  };
};

visPlugin.ownership = OWNERSHIPS.VPP;
visPlugin.id = 'vis';

export default visPlugin;
