import React from 'react';
import { shape, string } from 'prop-types';

const Subtitle = props => {
  const { wrapper, className, subtitle } = props;
  return (
    <div className={wrapper.className}>
      <span className={className}>{subtitle}</span>
    </div>
  );
};

Subtitle.propTypes = {
  className: string,
  subtitle: string,
  wrapper: shape({ className: string }),
};

export default Subtitle;
