import { DECORATION_TYPES } from './constants';
import SimpleWrapper from './simple-wrapper';

/**
 * Render list of components
 * @function renderListComponents
 * @param {Array} list
 * @param {Object} componentsToRender
 * @param {Object} calculatedProps
 * @param {Object} CustomWrapperComponent
 * @returns {Array}
 */
const renderListComponents = (
  list,
  componentsToRender,
  calculatedProps = {},
  CustomWrapperComponent = null,
  handleError = null,
  metrics = {},
) => {
  if (!list) {
    return null;
  }
  return list
    .filter(({ show = true, getShow }) => (getShow ? getShow(calculatedProps) : show))
    .map(({ type, id, props = {}, wrapperConfig, childrenConfig }, index) => {
      const Component = componentsToRender[type];

      if (!Component && handleError) {
        handleError(new Error(`Component ${type} not found in componentsToRender`), {
          ...metrics,
          decorationType: DECORATION_TYPES.HELPER,
        });
        return null;
      }

      let childrenElement = null;

      if (childrenConfig) {
        const Children = componentsToRender[childrenConfig.type];
        childrenElement = Children && (
          <Children {...childrenConfig.props} calculatedProps={calculatedProps} idx={index} />
        );
      }

      if (CustomWrapperComponent || wrapperConfig) {
        const WrapperComponent = CustomWrapperComponent || SimpleWrapper;

        return (
          <WrapperComponent
            key={id || type}
            wrapperConfig={wrapperConfig}
            calculatedProps={calculatedProps}
            componentsToRender={componentsToRender}
          >
            <Component {...props} calculatedProps={calculatedProps} idx={index}>
              {childrenElement || props?.children}
            </Component>
          </WrapperComponent>
        );
      }

      return (
        <Component key={id || type} {...props} calculatedProps={calculatedProps} idx={index}>
          {childrenElement || props?.children}
        </Component>
      );
    });
};

export default renderListComponents;
