import { startTransition } from 'react';
import { CORE_PROVIDERS, PROVIDER } from '../../../../../../figure/constants';
import { COMPONENT_TYPES, GALLERY_TABS } from '../../../../../helpers/constants';
import { trackEvent } from '@vpp-frontend-components/common';

const getModuleId = (provider, id, positionIndex) => String(id || `${provider}(${positionIndex})`);

const galleryCarouselMapper = (props, statics, calculatedProps, context) => {
  const {
    setHeaderUnclickeable,
    id: galleryId,
    accessibility_text,
    overlayAlpha,
    galleryType,
    figures,
    minHeight,
    maxHeight,
    track,
    shapes,
  } = props;
  const { page: app } = statics;
  const { setModalVisibility, setActiveModalTab } = calculatedProps;
  const { setCurrentIndex, position } = context;

  const handleTap = (type = 'picture', event = null) => {
    setActiveModalTab(type);
    if (event) {
      trackEvent(event);
    }
  };

  const getOnTapFigure = () => e => {
    startTransition(() => {
      e.preventDefault();
      setModalVisibility(true);

      setHeaderUnclickeable(true);
      handleTap(GALLERY_TABS.PICTURE, track);
    });
  };

  const list = figures
    .filter(figure => Object.values(CORE_PROVIDERS).includes(figure.provider))
    .map(item => {
      const { provider, id, positionIndex } = item;
      const moduleId = getModuleId(provider, id, positionIndex);

      if (provider === PROVIDER.IMAGE) {
        return {
          type: COMPONENT_TYPES.PICTURE,
          id: moduleId,
          props: {
            positionIndex,
            id,
            item,
            galleryType,
            maxHeight,
            minHeight,
            onTapFigure: getOnTapFigure(),
          },
          show: provider === PROVIDER.IMAGE,
          data: { provider: item.provider, button: item.button },
        };
      }

      return null;
    });

  const mergeFigures = newFigures =>
    [...list, ...newFigures].sort((a, b) => a.props?.item?.positionIndex - b.props?.item?.positionIndex);

  return {
    shapes,
    galleryId,
    counter: position?.current,
    setCounter: setCurrentIndex,
    app,
    accessibility_text,
    overlayAlpha,
    list,
    utils: {
      mergeFigures,
      getOnTapFigure,
      handleTap,
    },
  };
};

export default galleryCarouselMapper;
