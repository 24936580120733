import imageMapper from './image.mapper';
import Image from '../../../common/image.ui';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

export default {
  [COMPONENT_TYPES.IMAGE]: {
    mappers: [imageMapper],
    component: Image,
  },
};
