import React, { useRef } from 'react';
import { string, shape, func } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import QuestionsAiTextCollapse from '../questions-ai-text-collapse/questions-ai-text-collapse';
import IconFactory from '../../../icons/factory-builder';
import StyledLabel from '../../../styled-label';
import QuestionsAiUserFeedback from '../questions-ai-user-feedback/questions-ai-user-feedback';
import { tracking } from '../../../../utils/tracking';
import { Typography } from '@andes/typography';
import QuestionsAiDenounceMenu from '../questions-ai-denounce/questions-ai-denounce-menu';
import {
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_CASES,
} from '../../../../services/frontend-statsd/config/allowed-keys';
import { QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES } from '../../../../services/frontend-statsd/config/allowed-tags';

const namespace = `ui-vpp-questions-ai-suggestion`;

const QuestionsAiSuggestionDesktop = ({
  questionsAiSuggestion: { item_id: itemId, question, answer, answered_by, feedback, ask_seller, denounce },
  isUseful,
  handleCreateQuestionFromAi,
  showSnackbar,
  saveFrontendStatsd,
}) => {
  const cardRef = useRef(null);
  const { snackbar_message, label, track } = ask_seller || {};

  const handleOnClick = () => {
    tracking({ track });

    if (saveFrontendStatsd) {
      const dataStatsd = {
        key: `${QUESTIONS_AI_DATADOG_KEY_PREFIX}.${QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK}`,
        tags: {
          button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.ASK_SELLER,
          origin: track?.melidata_event?.event_data?.origin,
          source: 'vpp',
        },
      };

      saveFrontendStatsd(dataStatsd);
    }

    handleCreateQuestionFromAi({
      itemId,
      text: question.text,
      snackbar_message: snackbar_message ?? 'Tu pregunta se envió correctamente.',
    });
  };

  return (
    <div className={namespace}>
      <Card paddingSize={0} shadow="outline" ref={cardRef}>
        <CardContent className={`${namespace}__card-content`}>
          <div className={`${namespace}__content`}>
            <div className={`${namespace}__content-answer`}>
              <QuestionsAiTextCollapse answer={answer} />
              {denounce && (
                <QuestionsAiDenounceMenu
                  {...denounce}
                  showSnackbar={showSnackbar}
                  saveFrontendStatsd={saveFrontendStatsd}
                  answer={answer.text}
                />
              )}
            </div>

            <div className={`${namespace}__answered-by`}>
              {answered_by.text_icon && IconFactory(answered_by.text_icon.id, `${namespace}__icon`)}
              <StyledLabel
                className={`${namespace}__text ${namespace}__text--answered-by`}
                color={answered_by.color}
                font_size={answered_by.font_size}
                font_family={answered_by.font_family}
                text={answered_by.text}
              />
            </div>
          </div>
          <div className={`${namespace}__footer`}>
            {feedback && <QuestionsAiUserFeedback itemId={itemId} isUseful={isUseful} {...feedback} />}
            {ask_seller && (
              <Typography
                color="link"
                component="p"
                size="s"
                onClick={handleOnClick}
                data-testid="questions-ai-ask-seller"
                className={`${namespace}__ask-seller`}
                weight="semibold"
              >
                {label?.text}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

QuestionsAiSuggestionDesktop.propTypes = {
  questionsAiSuggestion: shape({
    item_id: string.isRequired,
    question: shape({
      text: string.isRequired,
      color: string.isRequired,
      font_size: string.isRequired,
      font_family: string.isRequired,
    }).isRequired,
    answer: shape({
      text: string.isRequired,
    }).isRequired,
    answered_by: shape({
      text: string.isRequired,
      color: string.isRequired,
      font_size: string.isRequired,
      font_family: string.isRequired,
    }).isRequired,
    feedback: shape({}),
    denounce: shape({}),
    ask_seller: shape({}),
  }).isRequired,
  isUseful: func.isRequired,
  handleCreateQuestionFromAi: func.isRequired,
  showSnackbar: func.isRequired,
  saveFrontendStatsd: func.isRequired,
};

QuestionsAiSuggestionDesktop.defaultProps = {
  questionsAiSuggestion: {
    feedback: {},
    denounce: {},
  },
};

export default QuestionsAiSuggestionDesktop;
