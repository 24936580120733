import APIService from '../../services/api';

import {
  FETCH_ERROR,
  TOGGLE_BOOKMARKED,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  FETCH_START,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  QUANTITY_SHOW_INPUT_CHANGE,
  SHOW_QUANTITY_ERROR,
  SHOW_HI_QUANTITY_ERROR,
  HIDE_HI_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  SET_COMPATIBILITIES_INPUT_VALUE,
  FETCH_COMPATS_FEEDBACK_CLOSE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  ON_DEMAND_IFRAME,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  PRELOAD_IFRAME,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  FETCH_MELIPLUS_INFO_START,
  FETCH_MELIPLUS_INFO_COMPLETE,
  FETCH_MELIPLUS_INFO_ERROR,
} from './types';

import { RENDER_MODES } from '../utils/onePayForAllUtils';

import { getDefaultErrorMessage } from '../utils/getDefaultErrorMessage';

import {
  execAddElementToList,
  execRemoveElementFromList,
  execFetchUpdateWishlist,
  execRemoveBookmark,
  execAddBookmark,
} from '../../utils/giftRegistry';

import { execFetchUpdatedCoupon, execPostCouponActivate } from '../executors/vip/coupons';
import { getFetchUpdatedCoupon, getPostCouponActivate } from '../getters/vip/coupons';
import { execTriggerBuyNowOnePayForAll, execTriggerSequencer } from '../executors/vip/1pay4all';
import { getTriggerBuyNowOnePayForAll, getTriggerSequencer } from '../getters/vip/1pay4all';
import { execFetchItemOnMeliplusUpdate } from '../executors/vip/meliplus';
import { getFetchItemOnMeliplusUpdate } from '../getters/vip/meliplus';
import { execToggleFollowSeller } from '../executors/vip/followers';
import { getToggleFollowSeller } from '../getters/vip/followers';
import { execRemoveInstallation, execUpdateInstallation } from '../executors/vip/installations';
import { getRemoveInstallation, getUpdateInstallation } from '../getters/vip/installations';
import {
  execShowAddToCartModal,
  execShowQuestionsAiModal,
  execUpdateComponentsBottomSheet,
} from '../executors/vip/on-demand-iframe';
import {
  getShowAddToCartModal,
  getShowQuestionsAiModal,
  getUpdateComponentsBottomSheet,
} from '../getters/vip/on-demand-iframe';
import { execIsUseful, execOnCreateQuestionFromAi, execPostQuestionFromAi } from '../executors/vip/questions-ai';
import { getIsUseful, getOnCreateQuestionFromAi, getPostQuestionFromAi } from '../getters/vip/questions-ai';
import { execAddToCartUpdate } from '../executors/vip/a2c';
import { getAddToCartUpdate } from '../getters/vip/a2c';
import {
  execFetchMoreQuestionsDesktop,
  execFetchMoreQuestionsMobile,
  execOnCreateQuestion,
  execOnCreateQuestionInView,
} from '../executors/vip/questions';
import {
  getFetchMoreQuestionsDesktop,
  getFetchMoreQuestionsMobile,
  getOnCreateQuestion,
  getOnCreateQuestionInView,
} from '../getters/vip/questions';
import { execFetchCompatibilities, execFetchCompatibleValues } from '../executors/vip/compatibilities';
import { getFetchCompatibilities, getFetchCompatibleValues } from '../getters/vip/compatibilities';
import { execFetchItemOnShippingUpdate, execFetchShippingCalculatorOptions } from '../executors/vip/shipping';
import { getFetchItemOnShippingUpdate, getFetchShippingCalculatorOptions } from '../getters/vip/shipping';
import { execCheckOnBulkSaleSetQuantity, execFetchItemOnSetQuantity } from '../executors/vip/quantity';
import { getCheckOnBulkSaleSetQuantity, getFetchItemOnSetQuantity } from '../getters/vip/quantity';
import {
  execCleanFormCompats,
  execGetCompatsProductDesktop,
  execGetCompatsProductServiceActions,
  execGetCompatsProductWebmobile,
} from '../executors/vip/compats';
import {
  getCleanFormCompats,
  getterCompatsProductDesktop,
  getterCompatsProductServiceActions,
  getterCompatsProductWebmobile,
} from '../getters/vip/compats';
import { execFetchComponents } from '../executors/vip/fetch-status';
import { getFetchComponents } from '../getters/vip/fetch-status';

const addElementToList = element => (dispatch, getState) => {
  const {
    components: { wishlist_save_button: wishlist },
  } = getState();

  execAddElementToList(element, dispatch, wishlist, SHOW_SNACKBAR, HIDE_SNACKBAR, FETCH_ERROR);
};

const removeElementFromList = element => (dispatch, getState) => {
  const {
    components: { wishlist_save_button: wishlist },
  } = getState();

  execRemoveElementFromList(element, dispatch, wishlist, TOGGLE_BOOKMARKED, SHOW_SNACKBAR, FETCH_ERROR);
};

const getCompatsProductServiceActions = ({
  selectedInput,
  updateState,
  stateComponents,
  feature_name,
  fetchStickyComplete,
  fetchComplete,
  type,
  updateWidgetData,
  isMobile = false,
}) => (dispatch, getState) =>
  execGetCompatsProductServiceActions(
    dispatch,
    getterCompatsProductServiceActions(
      selectedInput,
      updateState,
      stateComponents,
      feature_name,
      fetchStickyComplete,
      fetchComplete,
      type,
      updateWidgetData,
      isMobile,
      getState,
    ),
  );

const getCompatsProductDesktop = ({ selectedInput, updateState, feature_name, type }) => (dispatch, getState) =>
  execGetCompatsProductDesktop(
    dispatch,
    getterCompatsProductDesktop(selectedInput, updateState, feature_name, type, getState),
  );

const getCompatsProductWebmobile = ({ selectedInput, updateState, feature_name, type }) => (dispatch, getState) =>
  execGetCompatsProductWebmobile(
    dispatch,
    getterCompatsProductWebmobile(selectedInput, updateState, feature_name, type, getState),
  );

const getCompatsProduct = ({ selectedInput, updateState, feature_name, deviceType }, type) => {
  const isMobile = deviceType === 'mobile';
  return isMobile
    ? getCompatsProductWebmobile({ selectedInput, updateState, feature_name, type })
    : getCompatsProductDesktop({ selectedInput, updateState, feature_name, type });
};

const cleanCompatsFeedback = updateState => dispatch => {
  dispatch({ type: FETCH_COMPATS_FEEDBACK_CLOSE, payload: updateState });
};

const cleanFormCompats = ({ feature_name, deviceType, updateState }) => (dispatch, getState) =>
  execCleanFormCompats(dispatch, getCleanFormCompats(feature_name, deviceType, updateState, getState));

const fetchUpdatedWishlists = (forceChecked = null) => (dispatch, getState) => {
  const { id, app } = getState();
  execFetchUpdateWishlist(dispatch, id, app, UPDATE_GIFT_REGISTRY, forceChecked, FETCH_ERROR);
};

const toggleGiftRegistryCheckbox = check => dispatch => {
  dispatch({
    type: UPDATE_GIFT_REGISTRY_CHECKBOX,
    payload: {
      check,
    },
  });
};

const toggleBookmark = () => (dispatch, getState) => {
  const STATUS_OK = 'ok';
  const STATUS_ERROR = 'error';
  const STATUS_SUCCESS = 'success';
  const STATUS_NEUTRAL = 'neutral';
  const CALLED_FROM = 'wishlist_save_button';
  const {
    components: {
      bookmark: {
        is_bookmarked: isBookmarked,
        item_id: itemId,
        variation_id: variationId,
        selected_attributes: selectedAttributes,
      },
      wishlist_save_button,
    },
    csrfToken,
  } = getState();
  dispatch({ type: TOGGLE_BOOKMARK_OPTIMISTICALLY });

  if (isBookmarked) {
    execRemoveBookmark(dispatch, itemId, variationId, STATUS_NEUTRAL, STATUS_OK, STATUS_ERROR, CALLED_FROM);
  } else {
    execAddBookmark(
      dispatch,
      itemId,
      variationId,
      selectedAttributes,
      csrfToken,
      wishlist_save_button,
      STATUS_SUCCESS,
      STATUS_OK,
      STATUS_ERROR,
      CALLED_FROM,
    );
  }
};

const fetchBookmark = () => dispatch => {
  dispatch({
    type: TOGGLE_BOOKMARKED,
  });
};

const fetchItemOnShippingUpdate = shippingOptionId => (dispatch, getState) =>
  execFetchItemOnShippingUpdate(dispatch, getFetchItemOnShippingUpdate(shippingOptionId, getState));

const fetchItemOnMeliplusUpdate = updateAction => (dispatch, getState) =>
  execFetchItemOnMeliplusUpdate(dispatch, getFetchItemOnMeliplusUpdate(updateAction, getState));

const hideHiQuantityError = () => dispatch => {
  dispatch({ type: HIDE_HI_QUANTITY_ERROR });
};

const checkOnBulkSaleSetQuantity = data => async (dispatch, getState) =>
  execCheckOnBulkSaleSetQuantity(dispatch, getCheckOnBulkSaleSetQuantity(data, getState));

const fetchItemOnSetQuantity = quantity => (dispatch, getState) =>
  execFetchItemOnSetQuantity(dispatch, getFetchItemOnSetQuantity(quantity, getState));

const onQuantityShowInput = show => dispatch => {
  dispatch({
    type: QUANTITY_SHOW_INPUT_CHANGE,
    payload: {
      show,
    },
  });
};

const fetchComponents = (id, params, setIsLoading) => (dispatch, getState) =>
  execFetchComponents(dispatch, getFetchComponents(id, params, getState), setIsLoading);

const fetchVariationDetailsByIds = ids => (dispatch, getState) => {
  dispatch({ type: FETCH_START, ids });
  const { id } = getState();
  APIService.getProductVariationsDetails(id, { ids })
    .then(payload => dispatch({ type: FETCH_PRODUCT_VARIATIONS_DETAILS, payload }))
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const showVariationsError = (deviceType, actionKey) => dispatch => {
  if (deviceType === 'desktop') {
    dispatch({ type: SHOW_VARIATIONS_ERROR_DESKTOP });
  } else {
    dispatch({ type: SHOW_VARIATIONS_ERROR_MOBILE, payload: { actionKey } });
  }
};

const showQuantityError = () => dispatch => {
  dispatch({ type: SHOW_QUANTITY_ERROR });
  dispatch({ type: SHOW_HI_QUANTITY_ERROR });
};

const fetchMeliplusInfo = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_MELIPLUS_INFO_START });
  try {
    const state = getState();
    const loyaltyCard = state?.components?.loyalty_collapsible_card;
    const loyaltyProps = loyaltyCard?.benefits_props;
    const params = {
      shipping_original_amount: loyaltyProps?.shipping_original_amount,
      shipping_amount: loyaltyProps?.shipping_amount,
      cashback_amount: loyaltyProps?.cashback_amount,
      financing_group: loyaltyProps?.financing_group,
    };
    const data = await APIService.getMeliplusInfo(params);
    dispatch({ type: FETCH_MELIPLUS_INFO_COMPLETE, payload: data });
  } catch (error) {
    const state = getState();
    const fallback = state?.components?.loyalty_collapsible_card?.fallback;
    dispatch({ type: FETCH_MELIPLUS_INFO_ERROR, payload: fallback });
  }
};

const fetchMoreQuestionsDesktop = (itemId, offset) => (dispatch, getState) =>
  execFetchMoreQuestionsDesktop(dispatch, getFetchMoreQuestionsDesktop(itemId, offset, getState));

const fetchMoreQuestionsMobile = (itemId, offset) => (dispatch, getState) =>
  execFetchMoreQuestionsMobile(dispatch, getFetchMoreQuestionsMobile(itemId, offset, getState));

const onCreateQuestion = ({ itemId, text, track }) => (dispatch, getState) =>
  execOnCreateQuestion(dispatch, getOnCreateQuestion(itemId, text, track, getState));

const onCreateQuestionInView = ({ itemId, text, track, make_only_question }) => (dispatch, getState) =>
  execOnCreateQuestionInView(dispatch, getOnCreateQuestionInView(itemId, text, track, make_only_question, getState));

const onCreateQuestionFromAi = ({ text, snackbar_message, suggestionTrack, source }) => (dispatch, getState) =>
  execOnCreateQuestionFromAi(
    dispatch,
    getOnCreateQuestionFromAi(text, snackbar_message, suggestionTrack, source, getState),
  );

const fetchCompatibleValues = ({ domainId, labelId, values }) => (dispatch, getState) =>
  execFetchCompatibleValues(dispatch, getFetchCompatibleValues(domainId, labelId, values, getState));

const setSelectedValue = ({ labelId, value }) => dispatch => {
  dispatch({ type: SET_COMPATIBILITIES_INPUT_VALUE, labelId, value });
};

const fetchCompatibilities = ({ domainId, values }) => (dispatch, getState) =>
  execFetchCompatibilities(dispatch, getFetchCompatibilities(domainId, values, getState));

const fetchShippingCalculatorOptions = () => (dispatch, getState) =>
  execFetchShippingCalculatorOptions(dispatch, getFetchShippingCalculatorOptions(getState));

const addToCartUpdate = ({ action, quantity, target, onSuccess, onError }) => (dispatch, getState) =>
  execAddToCartUpdate(dispatch, getAddToCartUpdate(action, quantity, target, onSuccess, onError, getState));

const fetchUpdatedCoupon = () => (dispatch, getState) =>
  execFetchUpdatedCoupon(dispatch, getFetchUpdatedCoupon(getState));

const postCouponActivate = ({ track, ...params }) => (dispatch, getState) =>
  execPostCouponActivate(dispatch, getPostCouponActivate(track, params, getState));

const showSnackbar = ({ message, type, delay, called_from, action, className }) => (dispatch, getState) => {
  const { siteId } = getState();
  const DEFAULT_ERROR_MESSAGE = getDefaultErrorMessage(siteId);
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: message || DEFAULT_ERROR_MESSAGE,
      type,
      delay: delay || 3000,
      called_from: called_from || 'vip',
      action,
      className,
    },
  });
};

const hideSnackbar = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
};

const showAddToCartModal = ({ itemId, labelText }) => (dispatch, getState) =>
  execShowAddToCartModal(dispatch, getShowAddToCartModal(itemId, labelText, getState));

const updateComponentsBottomSheet = () => (dispatch, getState) =>
  execUpdateComponentsBottomSheet(dispatch, getUpdateComponentsBottomSheet(getState));

const showQuestionsAiModal = ({ target, isDismissible, disabled, called_from, source }) => (dispatch, getState) =>
  execShowQuestionsAiModal(
    dispatch,
    getShowQuestionsAiModal(target, isDismissible, disabled, called_from, source, getState),
  );

const showPaymentsSplitModal = ({ target }) => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params: {
      show: true,
      src: `${target}&parent_origin=${window?.location?.origin}`,
      isRedirectFlow: false,
      renderMode: RENDER_MODES.CONTAINER,
      isDismissible: true,
      fallbackConfig: {},
      customNamespace: 'split-payments',
    },
  });
};

const onDemandIframeClose = () => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params: {
      show: false,
      src: null,
      isRedirectFlow: false,
      fallbackConfig: null,
      statsdConfig: null,
      sequencerTrack: null,
      customNamespace: null,
      noCloseButton: false,
    },
    isFetching: false,
  });
};

const onDemandIframeUpdate = params => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params,
  });
};

const triggerBuyNowOnePayForAll = actionKey => (dispatch, getState) =>
  execTriggerBuyNowOnePayForAll(dispatch, getTriggerBuyNowOnePayForAll(actionKey, getState));

const openWishlistModalBS = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
  dispatch({ type: WISHLIST_SHOW_BOTTOMSHEET_MODAL });
};

const closeWishlistModalBS = isBookmarked => dispatch => {
  dispatch({ type: WISHLIST_CLOSE_BOTTOMSHEET_MODAL, payload: { isBookmarked } });
};

const redirectToLogin = ({ itemId, loginType, featureName }) => () => {
  try {
    APIService.redirectToLogin(itemId, loginType, featureName);
  } catch (error) {
    throw new Error(error);
  }
};

const triggerSequencer = actionKey => (dispatch, getState) =>
  execTriggerSequencer(dispatch, getTriggerSequencer(actionKey, getState));

const preloadIframeLoaded = () => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params: { isIframeLoaded: true } });
};

const preloadIframeClose = () => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params: { show: false } });
};

const preloadIframeUpdate = params => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params });
};

const toggleFollowSeller = () => (dispatch, getState) =>
  execToggleFollowSeller(dispatch, getToggleFollowSeller(getState));

const saveFrontendStatsd = data => (_, getState) => {
  const { app: referer_app } = getState();

  if (!data) {
    return;
  }

  data.tags.referer_app = referer_app;

  APIService.saveFrontendStatsd(data);
};

const postQuestionFromAi = ({ productId, itemId, text, track, attributes, quantity, source }) => (dispatch, getState) =>
  execPostQuestionFromAi(
    dispatch,
    getPostQuestionFromAi(productId, itemId, text, track, attributes, quantity, source, getState),
  );

const isUseful = ({ itemId, useful, id, response, track, source }) => (dispatch, getState) =>
  execIsUseful(dispatch, getIsUseful(itemId, useful, id, response, track, source, getState));

const updateInstallation = agency => (dispatch, getState) =>
  execUpdateInstallation(dispatch, getUpdateInstallation(agency, getState));

const removeInstallation = () => (dispatch, getState) =>
  execRemoveInstallation(dispatch, getRemoveInstallation(getState));

export {
  toggleBookmark,
  fetchBookmark,
  fetchComponents,
  fetchVariationDetailsByIds,
  fetchItemOnSetQuantity,
  fetchItemOnShippingUpdate,
  fetchItemOnMeliplusUpdate,
  onQuantityShowInput,
  fetchUpdatedCoupon,
  postCouponActivate,
  showVariationsError,
  showQuantityError,
  fetchMoreQuestionsDesktop,
  fetchMoreQuestionsMobile,
  onCreateQuestion,
  onCreateQuestionInView,
  fetchCompatibleValues,
  setSelectedValue,
  fetchCompatibilities,
  fetchShippingCalculatorOptions,
  addToCartUpdate,
  getCompatsProduct,
  cleanFormCompats,
  cleanCompatsFeedback,
  showSnackbar,
  hideSnackbar,
  showAddToCartModal,
  triggerBuyNowOnePayForAll,
  showPaymentsSplitModal,
  showQuestionsAiModal,
  onCreateQuestionFromAi,
  triggerSequencer,
  onDemandIframeClose,
  onDemandIframeUpdate,
  preloadIframeLoaded,
  preloadIframeClose,
  preloadIframeUpdate,
  openWishlistModalBS,
  closeWishlistModalBS,
  toggleGiftRegistryCheckbox,
  redirectToLogin,
  updateComponentsBottomSheet,
  addElementToList,
  removeElementFromList,
  fetchUpdatedWishlists,
  toggleFollowSeller,
  saveFrontendStatsd,
  postQuestionFromAi,
  isUseful,
  updateInstallation,
  removeInstallation,
  checkOnBulkSaleSetQuantity,
  hideHiQuantityError,
  getCompatsProductServiceActions,
  fetchMeliplusInfo,
};
