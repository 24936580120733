/* eslint-disable react/prop-types */
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';
import MAIN from './main';
import CLIPS from './clips-worker';
import CLIP_FIGURE from './clip-figure';
import GALLERY_CAROUSEL from './gallery-carousel';
import LIGHTBOX from './lightbox';
import VIRTUAL_TOUR from './virtual-tour';

const ClipsPlugin = props => {
  const { clipsConfig } = props;

  if (!clipsConfig?.hasClips) {
    return null;
  }

  return {
    ...MAIN,
    ...CLIP_FIGURE,
    ...CLIPS,
    ...GALLERY_CAROUSEL,
    ...LIGHTBOX,
    ...VIRTUAL_TOUR,
  };
};

ClipsPlugin.ownership = OWNERSHIPS.VPP;
ClipsPlugin.id = 'clips';

export default ClipsPlugin;
