/* eslint-disable react/prop-types */
import React from 'react';
import { shape, string } from 'prop-types';

import figureProviderFactory from '../../../../../../figure';
import StyledOverlay from '../../../../../../styled-overlay';
import useZoom from './use-zoom.hook';

const Figure = props => {
  const {
    figureCommon,
    provider,
    overlayAlpha,
    key,
    className,
    index,
    zoom,
    onClick,
    onFullscreen,
    preload,
    lazyload,
    width,
    height,
    fetchPriority,
    wrapper,
    overlayProps,
    zoomConfig,
    otherProps,
  } = props;

  const FigureProvider = figureProviderFactory(provider);
  const ref = useZoom(zoomConfig);

  return (
    <figure className={wrapper.className} {...wrapper.otherProps} ref={ref}>
      <FigureProvider.Figure
        {...figureCommon}
        key={key}
        className={className}
        index={index}
        zoom={zoom}
        onClick={onClick}
        onFullscreen={onFullscreen}
        preload={preload}
        lazyload={lazyload}
        width={width}
        height={height}
        fetchPriority={fetchPriority}
        overlayAlpha={overlayAlpha}
        {...otherProps}
      />
      {overlayAlpha && (
        <StyledOverlay
          className={overlayProps.className}
          color={overlayProps.color}
          overlayAlpha={overlayProps.overlayAlpha}
          app={overlayProps.app}
          {...overlayProps?.otherProps}
        />
      )}
    </figure>
  );
};

Figure.propTypes = {
  id: string.isRequired,
  provider: string.isRequired,
  overlayAlpha: string,
  zoomRef: shape({}).isRequired,
  pictureZoomOptions: shape({}).isRequired,
  figureProps: shape({}).isRequired,
  figureWrapperProps: shape({}).isRequired,
  overlayProps: shape({}).isRequired,
};

export default Figure;
