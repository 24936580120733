import { loadable } from 'nordic/lazy';

import multimediaMapper from './multimedia.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const Multimedia = loadable(() => import('./multimedia.ui'));

export default {
  [COMPONENT_TYPES.MULTIMEDIA]: {
    mappers: [multimediaMapper],
    component: Multimedia,
  },
};
