import {
  SHOW_QUANTITY_ERROR,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  FETCH_ON_SET_QUANTITY_START,
  SET_QUANTITY,
} from '../../../../actions/vip/types';

const quantityShowInputChange = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    available_quantity: {
      ...state.components.available_quantity,
      showQuantityInput: action.payload.show,
    },
  },
});

const fetchOnSetQuantityStart = state => ({
  ...state,
  components: {
    ...state.components,
    available_quantity: {
      ...state.components.available_quantity,
      isFetching: true,
      buttonFocus: false,
    },
  },
  isFetching: true,
});

const fetchOnSetQuantityComplete = (state, action) => ({
  ...state,
  ...action.payload,
  components: {
    ...state.components,
    ...action.payload.components,
    ...(state.components.compats_feedback
      ? {
          compats_feedback: {
            ...state.components.compats_feedback,
          },
        }
      : {}),
    ...(state.components.technical_specifications
      ? {
          technical_specifications: {
            ...state.components.technical_specifications,
          },
        }
      : {}),
    ...(state.components.highlighted_specs_attrs
      ? {
          highlighted_specs_attrs: {
            ...state.components.highlighted_specs_attrs,
          },
        }
      : {}),
    ...(state.components.highlighted_specs_attrs_swap
      ? {
          highlighted_specs_attrs_swap: {
            ...state.components.highlighted_specs_attrs_swap,
          },
        }
      : {}),
    ...(state.components.highlighted_specs_attrs_third_swap
      ? {
          highlighted_specs_attrs_third_swap: {
            ...state.components.highlighted_specs_attrs_third_swap,
          },
        }
      : {}),
    available_quantity: {
      ...action.payload.components.available_quantity,
      isFetching: false,
      buttonFocus: true,
    },
  },
  preload: action.payload.preload,
  isFetching: false,
});

const showQuantityError = state => {
  let errorMessage;
  if (state.components.available_quantity?.state === 'HIDDEN') {
    return {
      ...state,
      components: {
        ...state.components,
        available_quantity: {
          ...state.components.available_quantity,
        },
      },
    };
  }
  if (state.components.available_quantity?.picker?.error_message) {
    errorMessage = {
      ...state.components.available_quantity.picker.error_message,
      is_deferred: state.components.available_quantity.picker.error_message.is_deferred
        ? null
        : state.components.available_quantity.picker.error_message.is_deferred,
    };
  }
  return {
    ...state,
    components: {
      ...state.components,
      available_quantity: {
        ...state.components.available_quantity,
        picker: {
          ...state.components.available_quantity.picker,
          border_color: errorMessage ? 'red' : null,
          error_message: errorMessage,
        },
      },
    },
  };
};

const setQuantity = (state, action) => ({
  ...state,
  components: {
    ...state.components,
    available_quantity: {
      ...state.components.available_quantity,
      selected: action.quantity,
    },
    main_actions: {
      ...state.components.main_actions,
      actions: state.components.main_actions.actions.map(act => ({
        ...act,
        disabled: false,
        should_raise_errors: false,
      })),
    },
  },
});

const quantityMapper = {
  [QUANTITY_SHOW_INPUT_CHANGE]: quantityShowInputChange,
  [FETCH_ON_SET_QUANTITY_START]: fetchOnSetQuantityStart,
  [FETCH_ON_SET_QUANTITY_COMPLETE]: fetchOnSetQuantityComplete,
  [SHOW_QUANTITY_ERROR]: showQuantityError,
  [SET_QUANTITY]: setQuantity,
};

export { quantityShowInputChange, fetchOnSetQuantityStart };
export { fetchOnSetQuantityComplete, showQuantityError, setQuantity };
export default quantityMapper;
