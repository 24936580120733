import { removeRestrictions } from '../../../../../../clip-video/Provider';
import { CLIPS_VIDEO_PROVIDER } from '../../../../../../clip-video/constants';
import { MULTIMEDIA_PROVIDERS, PROVIDER } from '../../../../../../figure/constants';

const getAsyncTapHandler = ({ onceRef, playerRef, multimediaClipRef, snap, onPause }) => itemProvider =>
  new Promise(resolve => {
    if (!onceRef.current) {
      resolve();
    }

    if (itemProvider === PROVIDER.CLIP) {
      onceRef.current = false;
      resolve();
    }

    if ([...MULTIMEDIA_PROVIDERS, PROVIDER.IMAGE].includes(itemProvider)) {
      removeRestrictions(playerRef.current, CLIPS_VIDEO_PROVIDER)
        .then(() => {
          multimediaClipRef.current.hasStarted = false; // To force the clip's thumbnail to be shown
          onPause(playerRef.current, { currentVideo: snap.currentClip }); // Execute just the logic of the event
        })
        .finally(() => {
          onceRef.current = false;
          resolve();
        });
    }
  });

export default getAsyncTapHandler;
