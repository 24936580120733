import { kebabToPascal } from '../convert-case';

/**
 * Enhance the mapper function with the ownership and isCore values.
 * @function enhanceHoc
 * @param {string} ownership - The ownership to add to the element.
 * @param {boolean} isCore - The isCore value to add to the element.
 * @param {string} pluginId - The pluginId of the component.
 * @returns {Function} - The function that adds the ownership and isCore values to the element.
 * @param {Function} hoc - The hoc to enhance.
 * @param {string} id - The id of the component.
 * @returns {Function} - The enhanced hoc.
 */
const enhanceHoc = (hoc, ownership, isCore, id, pluginId) => {
  const hocWithId = WrapperComponent => hoc(WrapperComponent, kebabToPascal(id));

  hocWithId.ownership = ownership;
  hocWithId.isCore = isCore;
  hocWithId.id = id;
  hocWithId.pluginId = pluginId;

  return hocWithId;
};

const buildHocsDefinition = ({ def, acc, id, isCore, pluginId, ownership }) => {
  const currentHocs = (def?.hocs || []).map(hoc => enhanceHoc(hoc, ownership, isCore, id, pluginId));
  const accHocs = acc?.hocs || [];

  return {
    hocs: [...accHocs, ...currentHocs],
  };
};

export default buildHocsDefinition;
export { enhanceHoc };
