import throttle from 'lodash/throttle';
import { clickVideo, isMuted, mute, pause, play, reset, unmute, tapVideo } from '../../../../../clip-video/Provider';
import { trackEvent } from '../../../../../../lib/tracking';
import {
  showDesktopMediaElements,
  updateMobileMediaElements,
} from '../../../../../clip-multimedia/utils/multimedia-events';
import getMultimediaProps from './get-multimedia-props.helper';
import { GALLERY_MODE } from '../../../../helpers/constants';
import { CLIPS_VIDEO_PROVIDER, SNACKBARS_ID } from '../../../../../clip-video/constants';
import { constants } from '../../../../../../utils/constants';

const { DEVICE_TYPE } = constants;

// ignore coverage for internal videojs lib events
const getClipMethods = ({
  multimediaClipRef,
  playerRef,
  isFullscreen,
  isFullscreenRef,
  mouseHoverConfigRef,
  spinnerConfig,
  clipsConfig,
  isCurrentClips,
  showSnackbar,
  multimediaWatched,
  setMultimediaWatched,
  position,
  snap,
  deviceType,
  namespace,
}) => {
  const isDesktop = deviceType === DEVICE_TYPE.DESKTOP;

  const handleTouch = (index, { className }, { close }) => {
    const isLoading = multimediaClipRef.current.loading;

    if (!isLoading && className.includes('glass-screen') && deviceType === DEVICE_TYPE.MOBILE) {
      tapVideo(playerRef.current, CLIPS_VIDEO_PROVIDER);
      return;
    }

    if (!isLoading && className.includes('glass-screen')) {
      clickVideo(playerRef.current, CLIPS_VIDEO_PROVIDER);
    } else if (className.includes('exit-fullscreen')) {
      close();
    } else if (className.includes('unmuted')) {
      mute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    } else if (className.includes('muted')) {
      unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    }
  };

  const sendIntentionToViewTrack = () => {
    if (isCurrentClips && !multimediaWatched[position.current]) {
      const { current } = snap;
      const trackToSend = {
        ...current?.track_intention_to_view,
        melidata_event: {
          ...(current?.track_intention_to_view?.melidata_event || {}),
          event_data: {
            ...current?.track_intention_to_view?.melidata_event?.event_data,
            gallery_mode: isFullscreenRef.current ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      trackEvent(trackToSend);
      setMultimediaWatched(watchedList => {
        watchedList[position.current] = true;

        return watchedList;
      });
    }
  };

  const onSrcChanged = (player, isFirstRender) => {
    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP:
        pause(playerRef.current, CLIPS_VIDEO_PROVIDER);
        multimediaClipRef.current.paused = true;
        break;

      case DEVICE_TYPE.MOBILE:
        if (clipsConfig?.autoplay && !isCurrentClips && !isFirstRender) {
          reset(player, CLIPS_VIDEO_PROVIDER);
        }

        if (!clipsConfig?.autoplay && isFullscreen) {
          play(player, CLIPS_VIDEO_PROVIDER);
        }
        break;

      default:
        break;
    }
  };

  const onWaiting = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.loading = true;

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      isDesktop && mouseHoverConfigRef.current,
    );

    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP:
        showDesktopMediaElements(multimediaProps);
        break;

      case DEVICE_TYPE.MOBILE:
        spinnerConfig.current.delayId = setTimeout(() => {
          updateMobileMediaElements(multimediaProps);
        }, spinnerConfig.current.delay);
        break;

      default:
        break;
    }

    if (multimediaClipRef.current.error) {
      const { message, delay } = clipsConfig?.snackbars?.find(({ id }) => id === SNACKBARS_ID?.OWN_ERROR) || {};

      if (message) {
        spinnerConfig.current.delayId = setTimeout(() => {
          showSnackbar({
            message,
            type: 'generic',
            delay,
            called_from: isDesktop ? 'gallery_vip_clip' : `${namespace}-${deviceType}`,
          });
        }, spinnerConfig.current.delay);
      }
    }
  };

  const onPlay = () => {
    multimediaClipRef.current.paused = false;
  };

  const onPlaying = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.paused = false;
    multimediaClipRef.current.loading = false;
    multimediaClipRef.current.hasStarted = true;

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      isDesktop && mouseHoverConfigRef.current,
    );

    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP:
        showDesktopMediaElements(multimediaProps);
        break;

      case DEVICE_TYPE.MOBILE:
        updateMobileMediaElements(multimediaProps);
        break;

      default:
        break;
    }

    clearTimeout(spinnerConfig.current.delayId);
  };

  const onPause = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.paused = true;
    multimediaClipRef.current.loading = false;

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      isDesktop && mouseHoverConfigRef.current,
    );

    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP:
        showDesktopMediaElements(multimediaProps);
        break;

      case DEVICE_TYPE.MOBILE:
        updateMobileMediaElements(multimediaProps);
        clearTimeout(spinnerConfig.current.delayId);
        break;

      default:
        break;
    }
  };

  const onVolumeChange = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.muted = isMuted(player, CLIPS_VIDEO_PROVIDER);

    const multimediaProps = getMultimediaProps(
      currentVideo?.id,
      multimediaClipRef.current,
      isFullscreenRef.current,
      isDesktop && mouseHoverConfigRef.current,
      isDesktop && true,
    );

    switch (deviceType) {
      case DEVICE_TYPE.DESKTOP:
        showDesktopMediaElements(multimediaProps);
        break;

      case DEVICE_TYPE.MOBILE:
        updateMobileMediaElements(multimediaProps);
        break;

      default:
        break;
    }
  };

  const onTimeUpdate = throttle(player => {
    if (player.currentTime() > 0) {
      multimediaClipRef.current.timeToLeave += 1;
    }
  }, 1000);

  const onError = player => {
    multimediaClipRef.current.error = true;
    player?.error(null);
  };

  const onMute = muted => {
    if (muted) {
      unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    } else {
      mute(playerRef.current, CLIPS_VIDEO_PROVIDER);
    }
  };

  const onClickVideo = () => {
    clickVideo(playerRef.current, CLIPS_VIDEO_PROVIDER);
  };

  return {
    handleTouch,
    onSrcChanged,
    onWaiting,
    onPlay,
    sendIntentionToViewTrack,
    onPlaying,
    onPause,
    onVolumeChange,
    onTimeUpdate,
    onError,
    onMute,
    onClickVideo,
  };
};

export default getClipMethods;
