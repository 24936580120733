import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const mainMapper = props => {
  const {
    list,
    bookmark,
    onClickBookmark,
    showSnackbar,
    openWishlistModalBS,
    closeWishlistModalBS,
    updateGiftRegistry,
    toggleGiftRegistryCheckbox,
    fetchBookmark,
  } = props;

  return {
    list: [
      ...list,
      {
        type: COMPONENT_TYPES.BOOKMARK,
        props: {
          bookmark,
          onClickBookmark,
          openWishlistModalBS,
          closeWishlistModalBS,
          updateGiftRegistry,
          showSnackbar,
          toggleGiftRegistryCheckbox,
          fetchBookmark,
        },
        show: !!bookmark,
      },
    ],
  };
};

export default mainMapper;
