import MAIN from './main';
import IMAGE_LOADER from './image-loader';
import IMAGE from './image';
import GALLERY_CAROUSEL from './gallery-carousel';
import PICTURE from './picture';
import LIGHTBOX from './lightbox';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const CorePlugin = () => ({
  ...MAIN,
  ...IMAGE_LOADER,
  ...IMAGE,
  ...GALLERY_CAROUSEL,
  ...PICTURE,
  ...LIGHTBOX,
});

CorePlugin.ownership = OWNERSHIPS.VPP;
CorePlugin.isCore = true;

export default CorePlugin;
