import { useMemo, useState, useCallback } from 'react';
import setZoomLeftPosition from './set-zoom-left-position.helper';

const useZoomOptions = (zoomRef, namespace, layout) => {
  const [galleryOptions, setGalleryOptions] = useState({
    top: 0,
    left: 0,
  });

  const visibleClass = `${namespace}__zoom-container--visible`;

  const updateGalleryOptions = useCallback(() => {
    const firstThumbnail = document.querySelector('.ui-pdp-gallery__thumbnail:first-of-type')?.getBoundingClientRect();

    if (!firstThumbnail) {
      return;
    }

    setGalleryOptions({
      top: firstThumbnail.top,
      left: setZoomLeftPosition({ left: firstThumbnail.left, layout }),
    });
  }, [layout]);

  const zoomOptions = useMemo(() => {
    const onShow = () => {
      const zoom = zoomRef.current;

      if (zoom) {
        updateGalleryOptions();
        document.body.appendChild(zoom);
        zoom.classList.add(visibleClass);
      }
    };

    const onHide = () => {
      const zoom = zoomRef.current;
      if (zoom) {
        zoom.classList.remove(visibleClass);
      }
    };

    return { namespace: `${namespace}__zoom`, onShow, onHide };
  }, [namespace, visibleClass, zoomRef, updateGalleryOptions]);

  return { zoomOptions, galleryOptions };
};

export default useZoomOptions;
