import React from 'react';
import { TextField } from '@andes/textfield';
import classnames from 'classnames';
import QuantityInputButton from './quantity-input-button';
import QuantityInputCustomLabel from '../quantity-input-custom-label/quantity-input-custom-label';
import { namespace, quantityInputFieldPropTypes } from './model';

const QuantityInputField = ({
  ref,
  value = '',
  placeholder = '',
  onInputChange,
  onSubmit,
  error = null,
  title,
  suffix = '',
  keyboardInputMode = '',
  mobile = false,
  hasCustomOptions = false,
  quantityText = '',
  amount,
  confirm,
  bulkSale = false,
}) => {
  const numericValue = Number(value.replace(',', '.'));

  const handleKeyDown = e => {
    if (e.key === 'Enter' && value !== '') {
      onSubmit();
    }
  };
  return (
    <div className={classnames(`${namespace}__input-form`)}>
      <TextField
        inputProps={{ inputMode: keyboardInputMode, autoComplete: 'off' }}
        className={`${namespace}__input-textfield`}
        data-testid="quantity-input"
        autoFocus
        setField={inputRef => {
          if (ref) {
            ref.current = inputRef;
          }
        }}
        value={value}
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        label={title}
        suffix={suffix}
        helper={error?.message}
        modifier={error?.modifier}
      >
        {!mobile && !bulkSale && (
          <QuantityInputButton
            size="small"
            confirm={confirm}
            onSubmit={onSubmit}
            error={error}
            value={value}
            bulkSale={bulkSale}
            hasCustomOptions={hasCustomOptions}
          />
        )}
      </TextField>
      {!error && hasCustomOptions && numericValue > 0 && (
        <QuantityInputCustomLabel
          data-testid="quantity-input-custom-label"
          label={quantityText}
          amount={amount}
          namespace={namespace}
        />
      )}
      {(mobile || bulkSale) && (
        <QuantityInputButton
          confirm={confirm}
          onSubmit={onSubmit}
          error={error}
          value={value}
          bulkSale={bulkSale}
          hasCustomOptions={hasCustomOptions}
        />
      )}
    </div>
  );
};
QuantityInputField.propTypes = quantityInputFieldPropTypes;

export default QuantityInputField;
