/* eslint-disable no-console */
const IS_DEV = process.env.NODE_ENV === 'development';

/**
 * Log error info on console
 * @function logErrorInfo
 * @param {Object} error
 * @param {Object} metrics
 */
const logErrorInfo = (error, metrics) => {
  console.log('\n-----------------------------------------------------------\n');
  console.log(
    `Ocurrió un error en el componente de ${metrics.featureName}! \n\nDetalles del error:\n\n`,
    metrics,
    '\n',
  );
  console.log('\nStack trace:\n');
  console.error(error.stack);
  console.log('\n-----------------------------------------------------------\n');
};

/**
 * Error with metrics
 * @class ErrorWithMetrics
 * @extends Error
 * @param {Object} error
 * @param {Object} metrics
 * @returns {Object}
 */
class ErrorWithMetrics extends Error {
  constructor(error, metrics) {
    if (IS_DEV) {
      logErrorInfo(error, metrics);
    }
    const { decorationType, name, ownership, isCore, featureName, ...restMetrics } = metrics;
    const message = JSON.stringify({
      error: error.message,
      isCore,
      decorationType,
      name,
      ownership,
      featureName,
      ...restMetrics,
    });
    super(message);
    this.stack = error.stack;
  }
}

export { logErrorInfo, ErrorWithMetrics };
