const actionMapper = props => {
  const { action, onScrollComponent } = props;
  return {
    title: action?.label,
    target: action?.target,
    onScrollComponent: action?.target ? onScrollComponent : null,
  };
};

export default actionMapper;
