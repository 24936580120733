import { constants } from '@vpp-frontend-components/common';
import { PROVIDER } from '../../../../../../figure/constants';

const { DEVICE_TYPE } = constants;

const virtualTourMapper = (props, _, calculatedProps, context) => {
  const { figures, highlightedMultimedia } = props;

  const { modalVisibility, activeModalTab, setActiveModalTab, handlers } = calculatedProps;
  const { position, setCurrentIndex } = context;

  const { handleBeforeOpen, handleLightboxOnClose } = handlers;
  const allowedFigures = figures?.filter(({ provider }) => provider === PROVIDER.IMAGE);
  const mergeFigures = newFigures =>
    [...allowedFigures, ...newFigures].sort((a, b) => a?.positionIndex - b?.positionIndex);

  return {
    deviceType: DEVICE_TYPE.MOBILE,
    visible: modalVisibility,
    activeTab: activeModalTab,
    highlightedMultimedia,
    figures: figures?.filter(({ provider }) => provider === PROVIDER.IMAGE),
    onClose: handleLightboxOnClose,
    onBeforeOpen: handleBeforeOpen,
    onChange: newTab => setActiveModalTab(newTab),
    figureIndex: position?.current,
    setIndex: setCurrentIndex,
    position,
    setCurrentIndex,
    utils: {
      mergeFigures,
    },
  };
};

export default virtualTourMapper;
