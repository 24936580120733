import SellerValidated from '../../../../../../seller-validated';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import actionMapper from './action.mapper';

export default {
  [COMPONENT_TYPES.ACTION]: {
    component: SellerValidated,
    mappers: [actionMapper],
  },
};
