import { compose } from '@vpp-frontend-components/common';
import buildWithDefinitionHoc from './build-with-definition.hoc';
import { DECORATION_TYPES } from './constants';
import withCustomContext from './with-custom-context';

/**
 * flatMappers
 * @param {Array} mappers - Array of mappers
 * @returns {Function} - Function that takes mappers and returns an composed function that takes originalProps and returns the result of applying all mappers to it.
 */
const flatMappers = mappers => mappers.reduceRight((a, b) => originalProps => a(b(originalProps)));

/**
 * getMapperProps
 * @param {Array} mappers - Array of mappers
 * @returns {Function} - Function that takes originalProps and returns the result of applying all mappers to it.
 */
const getMapperProps = mappers => mappers?.length > 0 && flatMappers(mappers);

/**
 * @typedef ComponentData
 * @type {object}
 * @property {string} id - module id.
 * @property {string} name - component name.
 * @property {string} ownership - plugin ownership.
 * * @property {string} pluginId - plugin id.
 * @property {boolean} isCore - plugin isCore flag.
 
 */

/**
 * getInitializerMapper
 * @param {Function} handleError - Function that handles errors
 * @param {ComponentData} componentData - Object with name, ownership, isCore, id, and pluginId properties
 * @returns {Function} - Function that takes props and returns an object with the props and metrics
 */
const getInitializerMapper = (handleError, { name, ownership, isCore, id, pluginId }) => props => ({
  ...props,
  handleError,
  metrics: {
    component: {
      name,
      ownership,
      isCore,
      id,
      pluginId,
    },
    decorationType: DECORATION_TYPES.COMPONENT,
    ownership,
    pluginId,
    isCore,
  },
});

/**
 * Build components to render
 * @summary Es un helper que transforma el objeto de pluggableComponents en un objeto de componentes listos para renderizar,
 * generando y combinando los HOCs necesarios para cada componente.
 * withDefinitionnHoc es el encargado de inyectar al componente en cuestion:
 *  - las props ya mapeadas,
 *  - y el objeto componentsToRender.
 * De esta manera, cada componente queda envuelto al menos por el HOC withDefinitionnHoc.
 * @function buildComponentsToRender
 * @param {Object} componentDefinitions - Definitions by component
 * @param {Object} componentsToRenderRef - Components to render ref
 * @param {Function} handleError - Error handler function
 * @param {Object} statics - Statics object
 * @returns {Object} - Components to render
 * @param {Object} props - Component data
 * @returns {Object} - Component props with error handler
 */
const buildComponentsToRender = (componentDefinitions, componentsToRenderRef, handleError, statics) =>
  Object.entries(componentDefinitions)
    .filter(([id, def]) => id && !!def?.component)
    .reduce((acc, [id, def]) => {
      const { mappers, hocs, component, componentData, context } = def;

      const initializerMapper = getInitializerMapper(handleError, componentData);
      const mapperProps = getMapperProps([initializerMapper, ...mappers]);

      const withDefinitionHoc = buildWithDefinitionHoc(componentData, mapperProps, componentsToRenderRef, statics);

      const allHOCs = [withDefinitionHoc, ...hocs];

      if (context) {
        allHOCs.unshift(withCustomContext(context, id));
      }

      const enhance = compose(...allHOCs);

      return {
        ...acc,
        [id]: enhance(component),
      };
    }, {});

export default buildComponentsToRender;
export { getMapperProps, flatMappers, getInitializerMapper };
