import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const productStateMapper = (props, statics) => {
  const { tag, subtitle, label } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: `${namespace}__product-state`,
      },
    },
    list: [
      {
        type: COMPONENT_TYPES.PILL,
        props: {
          tag,
        },
        show: !!tag,
      },
      {
        type: COMPONENT_TYPES.SUBTITLE,
        props: {
          subtitle,
        },
        show: !!subtitle,
      },
      {
        type: COMPONENT_TYPES.LABEL,
        props: {
          label,
        },
        show: !!label,
      },
    ],
  };
};

export default productStateMapper;
