import { constants } from '@vpp-frontend-components/common';
import { DEFAULT_LIGHTBOX_SETTINGS } from '../../../../../helpers/constants';
import { PROVIDER } from '../../../../../../figure/constants';

const { DEVICE_TYPE } = constants;

const lightboxMapper = (props, _, calculatedProps, context) => {
  const { figures, shapes } = props;
  const { handlers } = calculatedProps;
  const { handleLightboxOnClose, handleBeforeOpen } = handlers;
  const { position, setCurrentIndex } = context;

  const pictures = figures
    .filter(figure => figure.provider === PROVIDER.IMAGE)
    .map(item => ({
      ...item,
      src: (item.zoom && item.zoom.src) || item.src,
      msrc: item.src,
      thumbnail: item.src,
    }));

  const mergePictures = newPictures =>
    [...pictures, ...newPictures].sort((a, b) => a?.positionIndex - b?.positionIndex);

  return {
    pictures,
    shapes,
    deviceType: DEVICE_TYPE.MOBILE,
    settings: DEFAULT_LIGHTBOX_SETTINGS.mobile,
    onBeforeOpen: handleBeforeOpen,
    onClose: handleLightboxOnClose,
    position,
    setCurrentIndex,
    utils: {
      mergePictures,
    },
  };
};

export default lightboxMapper;
