import GalleryContext from '../../../../../context';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import gallerySlideMapper from './gallery-slide.mapper';
import GallerySlide from './gallery-slide.ui';

export default {
  [COMPONENT_TYPES.GALLERY_SLIDE]: {
    mappers: [gallerySlideMapper],
    component: GallerySlide,
    context: GalleryContext,
  },
};
