/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import React from 'react';

import ThumbnailComponent from '../../../../../../thumbnail';
import figureProviderFactory from '../../../../../../figure';

const getOverlay = overlayConfig => {
  const { provider, icon, seeMoreLabel } = overlayConfig;
  const FigureProvider = figureProviderFactory(provider);

  const figureIcon = FigureProvider.Icon ? <FigureProvider.Icon {...icon} /> : null;
  const label = seeMoreLabel.show ? <span className={seeMoreLabel.className}>{seeMoreLabel.text}</span> : null;

  return label || figureIcon;
};

const Thumbnail = props => {
  const {
    wrapperLabel,
    key,
    className,
    picture,
    label,
    alt,
    dataTestId,
    width,
    height,
    onKeyDown,
    onClick,
    overlayConfig,
    otherProps,
  } = props;

  const overlay = getOverlay(overlayConfig);

  return (
    <label
      htmlFor={wrapperLabel.htmlFor}
      className={wrapperLabel.className}
      onMouseOver={wrapperLabel.handleMouseOver}
      {...wrapperLabel.otherProps}
    >
      <ThumbnailComponent
        key={key}
        className={className}
        picture={picture}
        label={label}
        alt={alt}
        width={width}
        height={height}
        onKeyDown={onKeyDown}
        onClick={onClick}
        data-testid={dataTestId}
        overlay={overlay}
        {...otherProps}
      />
    </label>
  );
};

export default Thumbnail;
