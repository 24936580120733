const figureMapper = props => {
  const { otherProps, clipsConfig, clipsRef } = props;

  const onMute = (...params) => clipsRef.current && clipsRef.current?.onMute(...params);
  const autoplay = clipsConfig?.autoplay;

  return {
    otherProps: { ...otherProps, onMute, autoplay },
  };
};

export default figureMapper;
