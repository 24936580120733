import ComponentRenderer from '../../../../../../../utils/with-plugins/helpers/component-renderer';
import { COMPATS_COMPONENT_TYPES } from '../../../../../constants';
import highlightsMapper from './highlights.mapper';

export default {
  [COMPATS_COMPONENT_TYPES.HIGHLIGHTS]: {
    component: ComponentRenderer,
    mappers: [highlightsMapper],
  },
};
