const COMPONENT_TYPES = {
  ZOOM: 'zoom',
  GALLERY: 'gallery',
  GALLERY_COLUMN: 'gallery_column',
  GALLERY_SLIDE: 'gallery_slide',
  IMAGE: 'image',
  LIGHTBOX: 'lightbox',
  CLIPS_WORKER: 'clips-worker',
  IMAGE_LOADER: 'image_loader',
  GALLERY_CAROUSEL: 'gallery_carousel',
  MULTIMEDIA: 'multimedia',
  CLIP_FIGURE: 'clip_figure',
  PICTURE: 'picture',
  RADIO_INPUT: 'radio_input',
  THUMBNAIL: 'thumbnail',
  FIGURE: 'figure',
  BOOKMARK: 'bookmark',
  SHARE: 'share',
  VIRTUAL_TOUR: 'virtual-tour',
  YOUTUBE: 'youtube',
};
const DEFAULT_GALLERY_LIMIT = 8;
const DEFAULT_GALLERY_SELECTED = 0;
const DEFAULT_LIGHTBOX_SETTINGS = {
  mobile: {
    thumbnailClassname: '.slick-slide',
    arrowEl: false,
    bgOpacity: 0.95,
    barsSize: {
      top: 0,
      bottom: 0,
    },
    loop: false,
  },
  desktop: namespace => ({
    thumbnailClassname: `.${namespace}__figure img`,
    showAnimationDuration: 1,
    showHideOpacity: true,
    barsSize: {
      top: 66,
      bottom: 66,
    },
  }),
};
const ID_CONTAINER = {
  FULL_SCREEN: 'player-fullscreen',
  GALLERY_SCREEN: 'video-container',
};
const GALLERY_MODE = {
  DEFAULT: 'default',
  FULL_SCREEN: 'fullscreen',
};
const GALLERY_TABS = {
  MULTIMEDIA: 'multimedia',
  PICTURE: 'picture',
};

const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const SNACKBARS_ID = { OWN_ERROR: 'OWN_ERROR', CONNECTION_ERROR: 'CONNECTION_ERROR' };

const MULTIMEDIA_CONFIG = {
  alt: 'Multimedia',
  title: 'Multimedia',
  allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
  frameBorder: 0,
  showSpinner: false,
};

const VIDEO_TYPES = {
  TOUR360: 'TOUR360',
  VIEW360: 'VIEW360',
};

export {
  COMPONENT_TYPES,
  BLANK_IMAGE,
  DEFAULT_GALLERY_LIMIT,
  DEFAULT_GALLERY_SELECTED,
  DEFAULT_LIGHTBOX_SETTINGS,
  GALLERY_MODE,
  GALLERY_TABS,
  ID_CONTAINER,
  SNACKBARS_ID,
  MULTIMEDIA_CONFIG,
  VIDEO_TYPES,
};
