import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const mainMapper = props => {
  const { list, figures, showSnackbar, clipsConfig } = props;

  return {
    list: [
      ...list,
      {
        type: COMPONENT_TYPES.CLIPS_WORKER,
        props: {
          figures,
          showSnackbar,
        },
        show: clipsConfig?.hasClips,
      },
    ],
  };
};

export default mainMapper;
