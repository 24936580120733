import ClipMultimedia from './clip-multimedia';
import clipMultimediaString from './utils/clip-multimedia-string';
import { getClipWrapper, toggleClipElement } from './utils/toggle-clips-elements';

import {
  showDesktopMediaElements,
  initMouseHoverConfig,
  shoutOffHover,
  shoutOnHover,
  updateMobileMediaElements,
} from './utils/multimedia-events';

export default ClipMultimedia;
export {
  clipMultimediaString,
  getClipWrapper,
  toggleClipElement,
  showDesktopMediaElements,
  initMouseHoverConfig,
  shoutOffHover,
  shoutOnHover,
  updateMobileMediaElements,
};
