import figureMapper from './figure.mapper';
import Figure from './figure.ui';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

export default {
  [COMPONENT_TYPES.FIGURE]: {
    mappers: [figureMapper],
    component: Figure,
  },
};
