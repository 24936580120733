import { DECORATION_TYPES, MAIN_TYPE } from './constants';
import validatePlugin from './validate-plugin';

/**
 * Function that resolves the plugins.
 * @function resolvePlugins
 * @param {Object} props - The props of the component.
 * @param {Array} plugins - The plugins to resolve.
 * @param {Function} handleError - The error handler function.
 * @param {boolean} debug - True if debug mode is enabled, false otherwise.
 * @returns {Array} - The resolved plugins.
 * @param {Array} plugins - The plugins to resolve.
 * @param {Function} handleError - The error handler function.
 * @param {boolean} debug - True if debug mode is enabled, false otherwise.
 * @returns {Array} - The resolved plugins.
 */
const resolvePlugins = (props, plugins, handleError, debug) =>
  plugins.reduce((accPlugins, plugin) => {
    const { ownership, isCore, id } = plugin;
    try {
      const definitions = plugin(props);

      if (!definitions) {
        // Significa que no matchea con la validación definida en el plugin, por ende queda descartado.
        return accPlugins;
      }

      // Me aseguro de que exista el main type el plugin core, para evitar errores de métricas.
      if (isCore) {
        definitions[MAIN_TYPE] = definitions[MAIN_TYPE] || {};
      }

      if (debug) {
        validatePlugin(plugin, definitions, ownership);
      }

      return [...accPlugins, { definitions, ownership, isCore, id }];
    } catch (error) {
      handleError(error, {
        decorationType: DECORATION_TYPES.PLUGIN,
        customMessage: `Error solving ${ownership} plugin.`,
        name: 'resolvePlugins',
        ownership,
        pluginId: id || ownership,
        isCore,
      });
      return accPlugins;
    }
  }, []);

export default resolvePlugins;
