import getFormattedPictures from './get-formatted-pictures';
import getClipsConfig from './get-clips-config';
import getFormattedBookmarks from './get-formatted-bookmarks';
import getFormattedShare from './get-formatted-share';
import getGalleryType from './get-gallery-type';
import getMultimedia from './get-multimedia';
import getFormattedClips from './get-formatted-clips';
import getFormattedVideos from './get-formatted-videos';
import getFigures from './get-figures';
import { MULTIMEDIA_CONFIG } from './constants';

const jsonToProps = ({
  default_selected,
  bookmark,
  share,
  show_share,
  highlighted_multimedia,
  multimediaConfigRaw,
  titleForAlt,
  wishlist,
  pictures: picturesRaw,
  picture_config,
  clips: clipsRaw,
  videos: videosRaw,
  gallery_type,
  id,
  csrfToken,
  accessibility_text,
  size_for_photo,
  max_height,
  min_height,
  overlay_alpha,
  ...rest
}) => {
  const pictures = getFormattedPictures({
    pictures: picturesRaw,
    titleForAlt,
    pictureConfig: picture_config,
  });
  const clips = getFormattedClips(clipsRaw);
  const clipsConfig = getClipsConfig(clipsRaw);
  const videos = getFormattedVideos(videosRaw);
  const multimedia = getMultimedia(highlighted_multimedia);
  const multimediaConfig = highlighted_multimedia || videos ? MULTIMEDIA_CONFIG : null;

  const figures = getFigures({
    multimedia,
    pictures,
    clips,
    videos,
    config: { multimedia: highlighted_multimedia },
  });

  return {
    id,
    share: getFormattedShare({ share, show_share }),
    bookmark: getFormattedBookmarks({ bookmark, id, csrfToken, wishlist }),
    galleryType: getGalleryType({ gallery_type, pictures }),
    accessibility_text,
    sizeForBiggerPhoto: size_for_photo,
    defaultSelected: default_selected,
    maxHeight: max_height,
    minHeight: min_height,
    figures,
    highlightedMultimedia: multimedia[0],
    clipsConfig,
    multimediaConfig,
    overlayAlpha: overlay_alpha,
    ...rest,
  };
};

export default jsonToProps;
