import ReviewsSummary from '../../../../../../../components/review-summary/review-summary.desktop';
import { REVIEWS_COMPONENT_TYPES } from '../../../../../constants';
import reviewsSummaryMapper from '../../../common/components/reviews-summary/reviews-summary.mapper';

export default {
  [REVIEWS_COMPONENT_TYPES.REVIEWS_SUMMARY]: {
    component: ReviewsSummary,
    mappers: [reviewsSummaryMapper],
  },
};
