import React, { useRef, useCallback, useMemo, memo, useContext } from 'react';

import propTypes from './helpers/proptypes';
import { MAIN_TYPE, DECORATION_TYPES, POSITION, OWNERSHIPS } from './helpers/constants';
import { snakeToPascal, kebabToPascal } from './helpers/convert-case';
import { logErrorInfo, ErrorWithMetrics } from './helpers/error-utils';
import renderListComponents from './helpers/render-list-components';
import addElementToList from './helpers/add-element-to-list';
import ComponentRenderer from './helpers/component-renderer';
import ComponentRendererWithRef from './helpers/component-renderer-with-ref';
import SimpleWrapper from './helpers/simple-wrapper';
import buildComponentsToRender from './helpers/build-components-to-render';
import debugPlugins from './helpers/debug-mode';
import joinDefinitions from './helpers/join-definitions';
import resolvePlugins from './helpers/resolve-plugins';
import StaticPropsContext from '../../components/context/static-props';

const IS_DEV = process.env.NODE_ENV === 'development';

/**
 * Function that checks if a plugin is the core plugin.
 * @function hasCorePlugin
 * @param {Array} plugins - The list of plugins to search in.
 * @returns {Object | undefined} - The core plugin found.
 */
const hasCorePlugin = plugins => !!plugins.find(plugin => plugin.isCore);

/**
 * withPlugins
 * @description Es un HOC que recibe una lista de plugins y retorna el componente con la lógica inyectada de los plugins que matcheen.
 * Un plugin proporciona las siguientes propiedades a los componentes definidos:
 *   - hocs: una lista de HOCs que se aplicarán al componente.
 *   - mappers: una lista de mappers que se aplicarán a los props del componente.
 *   - component: el componente que se renderizará.
 * @example   Implementación de ejemplo:
 * const enhance = compose(
 *   withFeatureFlag(FEATURE_NAME),
 *   connect(
 *     mapStateToProps,
 *     mapDispatchToProps,
 *   ),
 *   withPlugins(vpp, apparel, refurbished, mshops)({
 *     debugMode: true,
 *     namespace: 'vpp',
 *     featureName: "FEATURE_NAME",
 *   }),
 * );
 * @function withPlugins
 * @param {Array} plugins - Array of plugins
 * @returns {Function} - High order component
 * @param {Object} config - Config object
 * @returns {Function} - High order component
 * @param {Object} WrapperComponent - Wrapper component
 * @returns {Object} - High order component
 */
const withPlugins = (...allPlugins) => (options = { debugMode: false }) => WrapperComponent => {
  const [corePlugin, ...restPlugins] = allPlugins;
  const { debugMode, featureName } = options;
  const debug = IS_DEV && debugMode;

  if (!featureName) {
    throw new Error(
      'featureName is required. Add featureName in withPlugins(...plugins)({featureName: -> HERE <-})(Component)',
    );
  }
  if (!corePlugin.isCore) {
    throw new Error('Core plugin is required. Add ownership: "core".');
  }

  corePlugin.id = corePlugin.id || 'core';

  const HighOrderComponent = memo(props => {
    const componentsToRenderRef = useRef({});
    const staticProps = useContext(StaticPropsContext);
    const statics = useMemo(() => ({ ...options, ...staticProps }), [staticProps]);

    const handleError = useCallback((error, metrics) => {
      throw new ErrorWithMetrics(error, {
        ...metrics,
        featureName,
      });
    }, []);

    const WrapperComponentEnhanced = useMemo(() => {
      const healthyPlugins = [corePlugin, ...restPlugins];
      const pluginsWithErrors = []; // TODO: Add recovery feature

      const pluginDefinitions = resolvePlugins(props, healthyPlugins, handleError, debug);
      const componentDefinitions = joinDefinitions(pluginDefinitions, WrapperComponent, handleError);
      componentsToRenderRef.current = buildComponentsToRender(
        componentDefinitions,
        componentsToRenderRef,
        handleError,
        statics,
      );

      if (debug) {
        debugPlugins(allPlugins, pluginDefinitions, pluginsWithErrors, componentDefinitions, featureName);
      }

      return componentsToRenderRef.current[MAIN_TYPE];
    }, [handleError, props, statics]);

    if (!WrapperComponentEnhanced) {
      return null;
    }

    return <WrapperComponentEnhanced {...props} />;
  });

  HighOrderComponent.displayName = `withPlugins(${snakeToPascal(featureName)})`;

  return HighOrderComponent;
};

/*
    TODO: Extras - Nice to have

    In HighOrderComponent:
      const { plugins: pluginsIds } = props;
      const [recoveryData, setRecoveryData] = useState([]);

    In handleError(): 
      setRecoveryData(prev => [...prev, { ...metrics, featureName, error: errorWithMetrics }]);

    In WrapperComponentEnhanced:
      const filteredPluginsByIds = pluginsIds ? filterPluginsByIds(restPlugins, pluginsIds) : restPlugins;
      const { healthyPlugins, pluginsWithErrors } = filterPluginsByHealth(
          [corePlugin, ...filteredPluginsByIds],
          recoveryData,
        );

      if (hasCorePlugin(pluginsWithErrors)) {
        console.error(`❗ Error in core plugin of ${featureName} component`);
        return null;
      }
  */

export default withPlugins;
export {
  propTypes,
  ComponentRenderer,
  ComponentRendererWithRef,
  SimpleWrapper,
  MAIN_TYPE,
  DECORATION_TYPES,
  POSITION,
  OWNERSHIPS,
  renderListComponents,
  addElementToList,
  logErrorInfo,
  snakeToPascal,
  kebabToPascal,
  hasCorePlugin,
  ErrorWithMetrics,
};
