const compatsTitleMapper = props => {
  const { title, titleClassName, title_compats } = props;
  return {
    title,
    compats: title_compats,
    className: titleClassName,
  };
};

export default compatsTitleMapper;
