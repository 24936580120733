import clipFigureMapper from './clip-figure.mapper';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import ClipMultimedia from '../../../../../../clip-multimedia';

export default {
  [COMPONENT_TYPES.CLIP_FIGURE]: {
    mappers: [clipFigureMapper],
    component: ClipMultimedia,
  },
};
