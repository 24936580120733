import thumbnailMapper from './thumbnail.mapper';
import Thumbnail from './thumbnail.ui';
import { COMPONENT_TYPES } from '../../../../../helpers/constants';

export default {
  [COMPONENT_TYPES.THUMBNAIL]: {
    mappers: [thumbnailMapper],
    component: Thumbnail,
  },
};
