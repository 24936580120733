import React, { useContext } from 'react';
import { shape } from 'prop-types';
import GalleryDesktop from '../gallery.desktop';
import GalleryMobile from '../gallery.mobile';
import GalleryProvider from '../../gallery-with-plugins/context/gallery-provider';
import StaticPropsContext from '../../context/static-props';

const GalleryWrapper = props => {
  const { deviceType } = useContext(StaticPropsContext);

  switch (deviceType) {
    case 'mobile':
      return (
        <GalleryProvider {...props}>
          <GalleryMobile {...props} />
        </GalleryProvider>
      );

    case 'desktop':
      return (
        <GalleryProvider {...props}>
          <GalleryDesktop {...props} />
        </GalleryProvider>
      );

    default:
      return null;
  }
};

GalleryWrapper.propTypes = {
  props: shape({}).isRequired,
};

GalleryWrapper.defaultProps = {
  props: {},
};

export default GalleryWrapper;
