import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import galleryCarouselMapper from './gallery-carousel.mapper';
import GalleryCarousel from './gallery-carousel.ui';
import GalleryContext from '../../../../../context';

export default {
  [COMPONENT_TYPES.GALLERY_CAROUSEL]: {
    mappers: [galleryCarouselMapper],
    component: GalleryCarousel,
    context: GalleryContext,
  },
};
