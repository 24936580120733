import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const mainMapper = (props, statics) => {
  const { figures, track, limit, title, multimediaConfig, overlayAlpha, accessibility_text } = props;
  const { namespace, page: app } = statics;

  const nameItem = figures[0]?.alt || '';
  const attributeRole = accessibility_text ? 'group' : null;
  const attributeAriaLabel = accessibility_text ? `${accessibility_text}${nameItem && `, ${nameItem}`}` : null;

  return {
    wrapperConfig: {
      tag: 'div',
      props: { className: namespace, role: attributeRole, 'aria-label': attributeAriaLabel },
    },
    namespace,
    app,
    list: [
      { type: COMPONENT_TYPES.ZOOM },
      {
        type: COMPONENT_TYPES.GALLERY_COLUMN,
        props: {
          figures,
          track,
          limit,
          title,
          overlayAlpha,
          app,
        },
      },
      {
        type: COMPONENT_TYPES.LIGHTBOX,
        props: {
          figures,
          multimediaConfig,
        },
        getShow: ({ modalVisibility }) => !!modalVisibility,
      },
    ],
  };
};

export default mainMapper;
