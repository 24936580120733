import mainMapper from './main.mapper';
import { MAIN_TYPE } from '../../../../../../../utils/with-plugins/helpers/constants';
import withClips from '../../../common/hocs/with-clips.hoc';

export default {
  [MAIN_TYPE]: {
    mappers: [mainMapper],
    hocs: [withClips],
  },
};
