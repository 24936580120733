import injectClipsPropsToList from '../../../common/helpers/inject-clips-props-to-list.helper';

const galleryColumnMapper = props => {
  const { list, clipsConfig, clipsRef, handleClip, clipsInfo } = props;

  return {
    list: injectClipsPropsToList(list, { clipsConfig, clipsRef, handleClip, clipsInfo }),
  };
};

export default galleryColumnMapper;
