import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import labelMapper from './label.mapper';
import Label from './label.ui';

export default {
  [COMPONENT_TYPES.LABEL]: {
    component: Label,
    mappers: [labelMapper],
  },
};
