import buildDefinition from './build-definition';
import { DECORATION_TYPES } from './constants';

/**
 * @summary joinDefinitions:
 * Es un helper que se encarga de combinar los distintos plugins en un único objeto,
 * cuya clave es el id de componente y el valor es un objeto con las propiedades hocs, mappers, component y context.
 * Se les anexa el nombre del plugin a todas las propiedades para que puedan ser identificadas en el debug.
 * A los mappers se les inyecta el store (props originales), como segundo parametro, para que puedan acceder a él.
 * Function that joins the definitions of the plugins.
 * @function joinDefinitions
 * @param {Array} pluginDefinitions - The definitions of the plugins.
 * @param {Object} WrapperComponent - The main component.
 * @param {Function} handleError - The error handler function.
 * @returns {Object} - The joined definitions.
 * @param {Array} pluginDefinitions - The definitions of the plugins.
 * @param {Object} WrapperComponent - The main component.
 * @param {Function} handleError - The error handler function.
 * @returns {Object} - The joined definitions.
 */
const joinDefinitions = (pluginDefinitions, WrapperComponent, handleError) =>
  pluginDefinitions.reduce((accDefinitions, { definitions, ownership, isCore, id: pId }) => {
    const pluginId = pId || ownership;

    try {
      return Object.entries(definitions).reduce(
        (defsRest, [id, def]) => ({
          ...accDefinitions, // Acumulado de juntar todas las definiciones de todos los plugins recorridos
          ...defsRest, // Acumulado de juntar todas las definiciones del actual plugin
          [id]: buildDefinition(
            id,
            def,
            accDefinitions[id],
            WrapperComponent,
            ownership,
            isCore,
            pluginId,
            handleError,
          ),
        }),
        {},
      );
    } catch (error) {
      handleError(error, {
        decorationType: DECORATION_TYPES.PLUGIN,
        customMessage: `Error processing ${ownership} plugin.`,
        name: 'mergePlugins',
        ownership,
        pluginId,
        isCore,
      });
      return accDefinitions;
    }
  }, {});

export default joinDefinitions;
