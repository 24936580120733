/**
 * Replace an element in a list
 * @function replaceElementInList
 * @param {Array} list - The list to replace the element in.
 * @param {Object} element - The element to replace in the list.
 * @param {string} referenceType - The type of the element to replace.
 * @returns {Array} - The updated list with the element replaced.
 */
const replaceElementInList = (list, element, referenceType) => {
  if (!Array.isArray(list)) {
    throw new Error('List must be an array');
  }

  if (list?.length === 0) {
    throw new Error('List must not be empty');
  }

  if (!element) {
    throw new Error('Element must be provided');
  }

  if (typeof referenceType !== 'string') {
    throw new Error('Reference type must be a string');
  }

  const updatedList = Array.from(list);
  const index = updatedList.findIndex(item => item.type === referenceType);

  if (index !== -1) {
    updatedList.splice(index, 1, element);
  }

  return updatedList;
};

export default replaceElementInList;
