import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import SellerValidated from '../../../../../../seller-validated';
import linkLabelMapper from '../../../common/components/link-label/link-label.mapper';

export default {
  [COMPONENT_TYPES.LINK_LABEL]: {
    component: SellerValidated,
    mappers: [linkLabelMapper],
  },
};
