import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const titleContainerMapper = (props, statics) => {
  const { title, titleClassName, children } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: `${namespace}__title-container`,
      },
    },
    list: [
      {
        type: COMPONENT_TYPES.TITLE,
        props: {
          title,
          titleClassName,
        },
        show: !!title,
      },
      {
        type: COMPONENT_TYPES.CHILDREN,
        props: {
          children,
        },
        show: !!children,
      },
    ],
  };
};

export default titleContainerMapper;
