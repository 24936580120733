/* eslint-disable react/prop-types */
import React from 'react';
import propTypes from './proptypes';
import { shape, string } from 'prop-types';

/**
 * SimpleWrapper
 * @param {object} props
 * @returns {React.Element}
 */
const SimpleWrapper = ({ children, ref, componentsToRender, wrapperConfig, calculatedProps }) => {
  const { type, tag, props } = wrapperConfig || {};
  const { className, dataTestId, ...restProps } = props || {};
  const WrapperTag = type ? componentsToRender[type] : tag;

  if (!WrapperTag) {
    return <>{children}</>;
  }

  return (
    <WrapperTag
      ref={ref}
      className={className}
      data-testid={dataTestId}
      {...(type ? { calculatedProps } : {})}
      {...restProps}
    >
      {children}
    </WrapperTag>
  );
};

SimpleWrapper.propTypes = {
  ref: shape({}),
  children: propTypes.children,
  componentsToRender: propTypes.componentsToRender,
  wrapperConfig: shape({
    tag: string,
    type: string,
    props: shape({
      className: propTypes.className,
      dataTestId: propTypes.dataTestId,
    }),
  }),
};

export default SimpleWrapper;
