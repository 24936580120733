import React from 'react';
import { shape, string } from 'prop-types';

const Zoom = props => {
  const { className, zoomRef, style } = props;

  return <div ref={zoomRef} className={className} style={style} />;
};

Zoom.propTypes = {
  className: string.isRequired,
  zoomRef: shape().isRequired,
  style: shape().isRequired,
};

export default Zoom;
