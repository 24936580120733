import { useState } from 'react';
import { tracking } from '../../../utils/tracking';
import {
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_CASES,
} from '../../../services/frontend-statsd/config/allowed-keys';
import { QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES } from '../../../services/frontend-statsd/config/allowed-tags';

const NAMESPACE = 'ui-vpp-questions-ai';

const useDenounceMenu = ({ track, snackbar_message, showSnackbar, saveFrontendStatsd, source }) => {
  const [isVisibleMenu, setIsVisibleMenu] = useState(false);

  const handleOnDenounce = () => {
    if (saveFrontendStatsd) {
      const dataStatsd = {
        key: `${QUESTIONS_AI_DATADOG_KEY_PREFIX}.${QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK}`,
        tags: {
          button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.DENOUNCE,
          source,
        },
      };

      saveFrontendStatsd(dataStatsd);
    }

    tracking({ track });
    setIsVisibleMenu(false);
    showSnackbar({
      message: snackbar_message.text,
      type: snackbar_message.type,
      delay: 3000,
      called_from: 'questions_ai_denounce',
      source,
    });
  };

  const handleMenu = () => setIsVisibleMenu(prevState => !prevState);

  const getPath = ({ target }) => {
    let currentElem = target;
    const path = [];

    while (currentElem) {
      path.push(currentElem);
      currentElem = currentElem.parentNode;
    }

    return path;
  };

  const handleClickOutside = e => {
    if (!getPath(e).find(el => el.className === `${NAMESPACE}-denounce-content__li`)) {
      setIsVisibleMenu(false);
    }
  };

  return {
    isVisibleMenu,
    handleOnDenounce,
    handleMenu,
    handleClickOutside,
  };
};

export default useDenounceMenu;
