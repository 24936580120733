import { COMPONENT_TYPES } from '../../../../../helpers/constants';

const actionContainerMapper = (props, statics) => {
  const { action, onScrollComponent } = props;
  const { namespace } = statics;
  return {
    wrapperConfig: {
      tag: 'div',
      props: {
        className: `${namespace}`,
      },
    },
    list: [
      {
        type: COMPONENT_TYPES.ACTION,
        props: {
          action,
          onScrollComponent,
        },
        show: !!action,
      },
    ],
  };
};

export default actionContainerMapper;
