import { COMPONENT_TYPES } from '../../../../../../../components/header-with-plugins';

const highlightsMapper = props => {
  const { highlights } = props;
  return {
    list: highlights?.map(item => ({
      type: COMPONENT_TYPES.COMPATS,
      id: item.id,
      props: {
        ...item,
      },
    })),
  };
};

export default highlightsMapper;
