/* eslint-disable react/prop-types */
import React from 'react';
import { renderListComponents } from '../../../../../../../utils/with-plugins';

const RadioInput = ({ className, type, name, id, value, readOnly, checked, otherProps }) => (
  <input
    className={className}
    type={type}
    name={name}
    id={id}
    value={value}
    readOnly={readOnly}
    checked={checked}
    {...otherProps}
  />
);

const GallerySlide = ({ input, className, list, componentsToRender, handleError, metrics }) => (
  <>
    <RadioInput {...input} />
    <span className={className}>{renderListComponents(list, componentsToRender, {}, null, handleError, metrics)}</span>
  </>
);

export default GallerySlide;
