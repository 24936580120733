import React from 'react';
import { bool, node, string } from 'prop-types';
import get from 'lodash/get';
import { StoreConsumer } from '../../store/context';

const STATE = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
  DEFERRED: 'deferred',
  TRACK_HIDDEN: 'track_hidden',
};

// TODO: When backend add the state to the components, remove this lines.
const SHOW_ALWAYS = [
  'gallery',
  'fixed.gallery',
  'header',
  'header-octopus',
  'accesibility',
  'on_demand_iframe',
  'gallery_octopus',
  'fixed.gallery_octopus',
];
const getDefaultState = name => (SHOW_ALWAYS.includes(name) ? STATE.VISIBLE : STATE.HIDDEN);

const ALLOW_STATES_LIST = [STATE.VISIBLE, STATE.DEFERRED, STATE.TRACK_HIDDEN];

const Loader = () => <div className="ui-pdp-fetching-component" />;

const FeatureFlag = ({ children, loader, name, pathToComponent }) => (
  <StoreConsumer>
    {store => {
      const path = pathToComponent ? `state.${pathToComponent}` : `state.components.${name}`;
      const component = get(store, path, false);
      const state = get(component, 'state', getDefaultState(name)).toLowerCase();

      if (ALLOW_STATES_LIST.includes(state)) {
        const isFetching = get(store, 'state.isFetching', false);

        return (
          <>
            {loader && isFetching && <Loader />}
            {children}
          </>
        );
      }
      return null;
    }}
  </StoreConsumer>
);

FeatureFlag.propTypes = {
  children: node.isRequired,
  loader: bool,
  name: string.isRequired,
  pathToComponent: string,
};

FeatureFlag.defaultProps = {
  loader: true,
  pathToComponent: null,
};

export default FeatureFlag;
