const shareMapper = props => {
  const { share } = props;

  return {
    ...share,
    modifier: 'gallery',
    iconVariation: 'gallery',
    showLabel: false,
  };
};

export default shareMapper;
