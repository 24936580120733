import React from 'react';
import { DECORATION_TYPES, MAIN_TYPE } from './constants';

/**
 * Valida si un valor es un componente de React válido
 * @function isValidReactComponent
 * @param {any} component - Componente a validar
 * @returns {boolean} - true si es un componente de React válido, false de lo contrario
 * @throws {Error} - Lanza un error si el componente no es válido
 */
const isValidReactComponent = component => {
  // Verificar si es un componente de clase
  const isClassComponent = comp =>
    typeof comp === 'function' && (comp.prototype?.isReactComponent || comp.prototype instanceof React.Component);

  // Verificar si es un componente funcional
  const isFunctionalComponent = comp => typeof comp === 'function';

  // Verificar si es un componente que utiliza forwardRef
  const isForwardRefComponent = comp =>
    !!(comp.render || (comp.displayName && comp.displayName.includes('ForwardRef')));

  // Verificar si es un componente Memo
  const isReactMemoComponent = comp => !!(String(comp.$$typeof) === 'Symbol(react.memo)');

  // Devuelve true si es un componente válido
  return (
    isClassComponent(component) ||
    isFunctionalComponent(component) ||
    isForwardRefComponent(component) ||
    isReactMemoComponent(component)
  );
};

/**
 * Validate component
 * @function validateComponent
 * @param {any} component - Component to validate
 * @param {string} id - Component id
 * @param {string} ownership - Component ownership
 * @returns {any} - Validated component
 * @throws {Error} - Throws an error if the component is not valid
 */
const validateComponent = (component, id, ownership) => {
  if (!isValidReactComponent(component)) {
    throw new Error(
      `Component must be a valid React component (Name: ${component?.name ||
        'anonymous'} | Def: ${id} | Ownership: ${ownership})`,
    );
  }

  return component;
};

/**
 * Validate function
 * @function validateFunction
 * @param {any} fn - Function to validate
 * @param {string} type - Function type
 * @param {string} id - Function id
 * @param {string} ownership - Function ownership
 * @returns {any} - Validated function
 * @throws {Error} - Throws an error if the function is not valid
 */
const validateFunction = (fn, type, id, ownership) => {
  if (typeof fn !== 'function') {
    throw new Error(
      `${type} must be a function (Name: ${fn?.name || 'anonymous'} | Def: ${id} | Ownership: ${ownership})`,
    );
  }

  return fn;
};

/**
 * Validate object
 * @function validateObject
 * @param {any} obj - Object to validate
 * @param {string} type - Object type
 * @param {string} id - Object id
 * @param {string} ownership - Object ownership
 * @returns {any} - Validated object
 * @throws {Error} - Throws an error if the object is not valid
 */
const validateObject = (obj, type, id, ownership) => {
  if (typeof obj !== 'object') {
    throw new Error(
      `${type} must be an object (Name: ${obj?.name || 'anonymous'} | Def: ${id} | Ownership: ${ownership})`,
    );
  }

  return obj;
};

/**
 * Validate array
 * @function validateArray
 * @param {any[]} arr - Array to validate
 * @param {string} type - Array type
 * @param {string} id - Array id
 * @param {string} ownership - Array ownership
 * @returns {any[]} - Validated array
 * @throws {Error} - Throws an error if the array is not valid
 */
const validateArray = (arr, type, id, ownership) => {
  if (!Array.isArray(arr)) {
    throw new Error(`${type}'s must be an array (Def: ${id} | Ownership: ${ownership})`);
  }

  return arr;
};

/**
 * Validate plugin
 * @function validatePlugin
 * @param {Function} plugin - Plugin to validate
 * @param {Object} props - Plugin props
 * @param {Object} MainComponent - Main component
 * @returns {Object} - Validated plugin
 * @throws {Error} - Throws an error if the plugin is not valid
 */

const validatePlugin = (plugin, definitions, ownership) => {
  if (!plugin.isCore && plugin({})) {
    // Que un plugin admita un objeto vacio, significa que no tiene match condition
    throw new Error(
      'Plugin must have a match condition',
      JSON.stringify({ id: plugin.id, ownership: plugin.ownership }),
    );
  }

  validateObject(definitions, `${DECORATION_TYPES.DEFINITION}'s`, 'no-apply', ownership);

  if (!ownership) {
    throw new Error('Plugin must have an ownership');
  }

  if (definitions?.[MAIN_TYPE]?.component) {
    throw new Error(
      'Definition MAIN does not support component. Inject MainComponent in withPlugins(...plugins)(config)( -> HERE <- )',
    );
  }

  Object.entries(definitions).forEach(([id, def]) => {
    // Valido si la definición es un objeto
    validateObject(def, DECORATION_TYPES.DEFINITION, id, ownership);
    // hocs
    if (def?.hocs) {
      validateArray(def?.hocs, DECORATION_TYPES.HOC, id, ownership).map(fn =>
        validateFunction(fn, DECORATION_TYPES.HOC, id, ownership),
      );
    }

    // mappers
    if (def?.mappers) {
      validateArray(def?.mappers, DECORATION_TYPES.MAPPER, id, ownership).map(fn =>
        validateFunction(fn, DECORATION_TYPES.MAPPER, id, ownership),
      );
    }

    // component
    if (def?.component && id !== MAIN_TYPE) {
      validateComponent(def?.component, id, ownership);
    }
  });

  return definitions;
};

export default validatePlugin;
