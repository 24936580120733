import { startTransition } from 'react';
import { MULTIMEDIA_PROVIDERS, PROVIDER, VIRTUAL_TOUR_PROVIDERS } from '../../../../../../figure/constants';
import { COMPONENT_TYPES, GALLERY_TABS } from '../../../../../helpers/constants';

const getModuleId = (provider, id, positionIndex) => String(id || `${provider}(${positionIndex})`);

const galleryCarouselMapper = (props, _, calculatedProps) => {
  const { utils, galleryType, allFigures } = props;
  const { setModalVisibility } = calculatedProps;

  const { mergeFigures, handleTap } = utils;

  const getOnTapFigure = button => e => {
    startTransition(() => {
      e.preventDefault();
      setModalVisibility(true);

      handleTap(GALLERY_TABS.MULTIMEDIA, button?.track);
    });
  };

  const multimediaFigures = allFigures
    .filter(figure => Object.values(MULTIMEDIA_PROVIDERS).includes(figure.provider))
    .map(item => {
      const { id, provider, button, positionIndex } = item;

      if (Object.values(VIRTUAL_TOUR_PROVIDERS).includes(provider)) {
        return {
          type: COMPONENT_TYPES.MULTIMEDIA,
          id: getModuleId(provider, id, positionIndex),
          props: {
            id,
            item,
            provider,
            galleryType,
            onTapFigure: getOnTapFigure(button),
          },
          show: Object.values(VIRTUAL_TOUR_PROVIDERS).includes(provider),
          data: { provider, button },
        };
      }

      if (provider === PROVIDER.YOUTUBE) {
        return {
          type: COMPONENT_TYPES.MULTIMEDIA,
          id: getModuleId(provider, id, positionIndex),
          props: {
            id,
            item,
            provider,
            galleryType,
            onTapFigure: getOnTapFigure(button),
          },
          show: provider === PROVIDER.YOUTUBE,
          data: { provider, button },
        };
      }

      return null;
    });

  return {
    list: mergeFigures(multimediaFigures).map(e => ({
      ...e,
      props: { ...e.props },
    })),
  };
};

export default galleryCarouselMapper;
