import getClipMethods from '../../../common/helpers/get-clip-methods.helper';
import getAsyncTapHandler from './get-async-tap-handler.hook';

const clipsMapper = (props, statics, _, context) => {
  const { figures, showSnackbar, clipsRef, clipsConfig, clipsInfo } = props;
  const { deviceType, namespace } = statics;
  const { isFullscreen } = context;

  const { isCurrentClips, isPreviousClips } = clipsInfo;

  const position = { ...context.position, ...clipsInfo.position };
  const snap = { ...context.snap, ...clipsInfo.snap };

  const getMethods = ({ multimediaClipRef, playerRef, isFullscreenRef, spinnerConfig, errorConfig }) =>
    getClipMethods({
      multimediaClipRef,
      playerRef,
      clipsConfig,
      isCurrentClips,
      isFullscreen,
      spinnerConfig,
      isFullscreenRef,
      errorConfig,
      showSnackbar,
      namespace,
      deviceType,
    });

  const getHandler = ({ onceRef, playerRef, multimediaClipRef, onPause }) =>
    getAsyncTapHandler({ onceRef, clipsConfig, playerRef, multimediaClipRef, snap, onPause });

  return {
    getMethods,
    getHandler,
    figures,
    clipsConfig,
    deviceType,
    ref: clipsRef,
    position,
    snap,
    isCurrentClips,
    isPreviousClips,
    isFullscreen,
  };
};

export default clipsMapper;
