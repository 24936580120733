import { PROVIDER } from '../../../../../../figure/constants';

const clipFigureMapper = (props, statics) => {
  const { item, id, clipsConfig, onTapFigure } = props;
  const { namespace, deviceType } = statics;

  return {
    onClick: onTapFigure,
    autoplay: clipsConfig?.autoplay,
    className: `${PROVIDER.CLIP} ${namespace}__clip`,
    deviceType,
    id,
    ...item,
  };
};

export default clipFigureMapper;
