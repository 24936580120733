const lightboxMapper = props => {
  const { clipsRef, clipsInfo, position } = props;

  return {
    onTouch: (...params) => clipsRef?.current && clipsRef?.current?.handleTouch(...params),
    position: { ...position, ...clipsInfo.position },
  };
};

export default lightboxMapper;
