/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import injectClipsPropsToList from '../helpers/inject-clips-props-to-list.helper';
import { PROVIDER } from '../../../../../figure/constants';
import GalleryContext from '../../../../context';
import usePreviousWithState from '../../../../../../hooks/use-previous-with-state';

const isItemClip = item => item?.provider === PROVIDER.CLIP;

const withClips = (WrappedComponent, id) => {
  const HighOrderComponent = React.memo(props => {
    const { position, snap } = useContext(GalleryContext);
    const clipsRef = useRef(null);

    const { clipsConfig, list, figures: items } = props;
    const { hasClips, size } = clipsConfig;

    const currentIndex = position?.current;
    const prevIndex = position?.previous;
    const currentItem = snap?.current;
    const previousItem = snap?.previous;

    const handleClip = useCallback(() => {
      if (clipsRef.current) {
        clipsRef.current.clipHandler();
      }
    }, []);

    const clipHandlerAsync = (...params) =>
      clipsRef.current ? clipsRef.current?.clipHandlerAsync(...params) : Promise.resolve();

    const [currentIndexClip, setCurrentIndexClip] = useState(() => (hasClips ? items.findIndex(isItemClip) : -1));
    const prevIndexClip = usePreviousWithState(currentIndexClip);

    useEffect(() => {
      if (size > 1) {
        const currentClipIndex = items.findIndex((item, index) => {
          const isCurrentOrNext = typeof prevIndexClip === 'number' ? index === currentIndex : index >= currentIndex;
          return isCurrentOrNext && isItemClip(item);
        });

        if (currentClipIndex !== -1) {
          setCurrentIndexClip(currentClipIndex);
        }
      }
    }, [items, currentIndex, prevIndexClip, size]);

    const nextIndexClip = useMemo(() => {
      let nextClipIndex = -1;

      if (size > 1 && currentIndexClip !== -1) {
        nextClipIndex = items.findIndex((item, index) => index > currentIndexClip && isItemClip(item));

        if (nextClipIndex === -1) {
          // istanbul ignore next
          nextClipIndex = items.findIndex(item => isItemClip(item));
        }
      }

      return nextClipIndex;
    }, [items, currentIndexClip, size]);

    const currentItemClip = items[currentIndexClip];
    const nextItemClip = items[nextIndexClip];
    const previousItemClip = items[prevIndexClip];

    const isCurrentClips = isItemClip(currentItem) && currentIndex === currentIndexClip;
    const isPreviousClips = isItemClip(previousItem || {}) && currentIndex !== prevIndex;

    const clipsProps = useMemo(
      () => ({
        clipsConfig,
        handleClip,
        clipHandlerAsync,
        clipsRef,
        clipsInfo: {
          position: {
            currentClip: currentIndexClip,
            prevClip: prevIndexClip,
            nextClip: nextIndexClip,
          },
          snap: {
            currentClip: currentItemClip || {},
            nextClip: nextItemClip || {},
            prevClip: previousItemClip || {},
          },
          isCurrentClips,
          isPreviousClips,
        },
      }),
      [
        clipsConfig,
        currentIndexClip,
        currentItemClip,
        handleClip,
        isCurrentClips,
        isPreviousClips,
        nextIndexClip,
        nextItemClip,
        prevIndexClip,
        previousItemClip,
      ],
    );

    return <WrappedComponent {...props} list={injectClipsPropsToList(list, clipsProps)} {...clipsProps} />;
  });

  HighOrderComponent.displayName = `WithClips(${id})`;
  return HighOrderComponent;
};

export default withClips;
