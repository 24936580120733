import { COMPONENT_TYPES } from '../../../../../helpers/constants';
import pillMapper from '../../../common/components/pill/pill.mapper';
import Pill from '../../../common/components/pill/pill.ui';

export default {
  [COMPONENT_TYPES.PILL]: {
    component: Pill,
    mappers: [pillMapper],
  },
};
