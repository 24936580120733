import { constants } from '@vpp-frontend-components/common';
import { DEFAULT_LIGHTBOX_SETTINGS } from '../../../../../helpers/constants';

const { DEVICE_TYPE } = constants;

const lightboxMapper = (props, statics, calculatedProps, context) => {
  const { figures, multimediaConfig } = props;
  const { setModalVisibility } = calculatedProps;
  const { namespace } = statics;
  const { setIsFullscreen, setCurrentIndex, position } = context;

  const handleBeforeOpen = () => {
    setIsFullscreen(true);
  };

  const handleLightboxOnClose = () => {
    setIsFullscreen(false);
    setModalVisibility(false);
  };

  return {
    pictures: figures,
    deviceType: DEVICE_TYPE.DESKTOP,
    settings: DEFAULT_LIGHTBOX_SETTINGS.desktop(namespace),
    videoConfig: multimediaConfig,
    onBeforeOpen: handleBeforeOpen,
    onClose: handleLightboxOnClose,
    position,
    setCurrentIndex,
  };
};

export default lightboxMapper;
