import React from 'react';
import { kebabToPascal } from './convert-case';

/**
 * Build a high order component with the definition of the component
 * @function buildWithDefinitionHoc
 * @param {Object} componentData - The data of the component.
 * @param {Function} mapperProps - The mapper props function.
 * @param {Object} componentsToRenderRef - The components to render ref.
 * @param {Object} statics - The statics object.
 * @returns {Function} - The high order component.
 */
const buildWithDefinitionHoc = (componentData, mapperProps, componentsToRenderRef, statics) => WrappedComponent => {
  const { id } = componentData;
  const HighOrderComponent = React.memo(props => {
    if (!props) {
      return null;
    }

    const propsWithExtras = { ...props, statics };
    const mappedProps = mapperProps ? mapperProps(propsWithExtras) : propsWithExtras;

    // Solo se puede acceder a calculatedProps, context y statics en los mappers (no en el componente ui);
    delete mappedProps.calculatedProps;
    delete mappedProps.context;
    delete mappedProps.statics;

    return <WrappedComponent {...mappedProps} componentsToRender={componentsToRenderRef.current} />;
  });

  HighOrderComponent.displayName = `withDefinition(${kebabToPascal(id)})`;
  return HighOrderComponent;
};

export default buildWithDefinitionHoc;
