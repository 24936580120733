/* eslint-disable react/prop-types */
import MAIN from './main';
import SHARE from './share';
import { OWNERSHIPS } from '../../../../../../utils/with-plugins';

const SharePlugin = props => {
  const { share } = props;

  if (!share) {
    return null;
  }

  return {
    ...MAIN,
    ...SHARE,
  };
};

SharePlugin.ownership = OWNERSHIPS.VPP;
SharePlugin.id = 'share';

export default SharePlugin;
