import { idToUrl } from '../../../lib/id-to-url';
import { PROVIDER } from '../../figure/constants';

const getFormattedPictures = ({ pictures, titleForAlt, pictureConfig }) =>
  pictures &&
  pictures.map(picture => {
    if (picture.url) {
      return {
        src: picture.url.src,
        src2x: picture.url.src2x,
        width: picture.width || 500,
        height: picture.height || 500,
        provider: PROVIDER.IMAGE,
        alt: titleForAlt,
        a11yLabel: picture.alt,
      };
    }

    const {
      template,
      template_2x: template2x,
      template_zoom: templateZoom,
      template_zoom_2x: templateZoom2x,
      template_thumbnail: templateThumbnail,
      template_thumbnail_2x: templateThumbnail2x,
    } = pictureConfig || {};

    return {
      ...picture,
      src: idToUrl(picture.id, template, picture.sanitized_title),
      src2x: template2x ? idToUrl(picture.id, template2x, picture.sanitized_title) : null,
      thumbnail: {
        src: templateThumbnail ? idToUrl(picture.id, templateThumbnail, picture.sanitized_title) : null,
        src2x: templateThumbnail2x ? idToUrl(picture.id, templateThumbnail2x, picture.sanitized_title) : null,
      },
      zoom: {
        src: templateZoom ? idToUrl(picture.id, templateZoom, picture.sanitized_title) : null,
        src2x: templateZoom2x ? idToUrl(picture.id, templateZoom2x, picture.sanitized_title) : null,
      },
      width: picture.width || 500,
      height: picture.height || 500,
      provider: PROVIDER.IMAGE,
      alt: titleForAlt,
      a11yLabel: picture.alt,
    };
  });

export default getFormattedPictures;
